import { INPUT_TYPE } from "../../../../utils/GlobalConstants"
export const initialState = {
  authentication: false,
  dnsServer: "",
  gateway: "",
  host: "",
  nic: "",
  passphrase: "",
  password: "",
  port: "",
  proxy: false,
  serialNumber: "",
  staticIp: "",
  username: ""
}

export const updateReducer = (state, action) => {
  switch (action.type) {
  case "UPDATE_FIELD":
    if (action.field === "proxy" && !action.value) {
      return { ...state, authentication: false, host: "", password: "", port: "", proxy: false, username: "" }
    }
    if (action.field === "authentication" && !state.proxy) {
      return state
    }
    return { ...state, [action.field]: action.value }
  case "RESET_FORM":
    return initialState
  }
}

export const transformData = (data) => {
  return {
    defaultGateway: data?.gateway,
    dnsServer: data?.dnsServer,
    isoImagePassPhrase: btoa(data.passphrase),
    networkInterfaceCard: data?.nic,
    proxy: {
      authentication: {
        password: btoa(data.password),
        userName: data.username
      },
      authenticationEnabled: data.authentication,
      host: data.host,
      port: data.port
    },
    proxyEnabled: data.proxy,
    serialNumber: data.serialNumber,
    staticIpAddress: data.staticIp
  }
}

export const textFields = [
  { key: "serialNumber", label: "Serial number", required: true },
  { key: "nic", label: "Network Interface card(NIC)", required: true },
  { info: "Eg. 192.168.0.1", key: "staticIp", label: "Static IP address", required: true },
  { key: "gateway", label: "Default Gateway", required: true },
  { key: "dnsServer", label: "DNS server", required: true },
  { key: "passphrase", label: "Passphrase", required: true, type: "password" },
  { key: "proxy", label: "Proxy", type: INPUT_TYPE.TOGGEL_INPUT },
  { key: "host", label: "Host" },
  { key: "port", label: "Port" },
  { key: "authentication", label: "Authentication", type: INPUT_TYPE.TOGGEL_INPUT },
  { key: "username", label: "User name" },
  { key: "password", label: "Password", type: "password" }
]

export const validatePassphrase = (passphrase) => {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,64}$/
  return regex.test(passphrase)
}

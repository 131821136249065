import { Tab, Tabs } from "@mui/material"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import styles from "./CertificateProvisioning.module.scss"
import FilterSection from "./internals/FilterSection"
import CompletedTasks from "./Tabs/CompletedTasks/CompletedTasks"
import PendingTasks from "./Tabs/PendingTasks/PendingTasks"
import RejectedTasks from "./Tabs/RejectedTasks/RejectedTasks"

import TabPanel from "../../../../components/TabPanel/TabPanel"
import { TasksAuthElement } from "../../../../utils/Constants/Auth/tasks"
import useAccess from "../../../../utils/CustomHooks/useAccess"
import { classNames } from "../../../../utils/styles/helper"

const CertificateProvisioning = () => {
  const { t } = useTranslation(["tasks"])
  const [value, setValue] = useState(0)

  const hasPendingTasksAccess = useAccess(TasksAuthElement.ReadPendingTasks)
  const hasRejectedTasksAccess = useAccess(TasksAuthElement.ReadRejectedTasks)
  const hasCompletedTasksAccess = useAccess(TasksAuthElement.ReadCompletedTasks)

  const allTabs = [
    {
      component: (
        <PendingTasks />
      ),
      hasAccess: hasPendingTasksAccess,
      id: 1,
      label: t("tabs.pendingTasks")
    },
    {
      component: (
        <RejectedTasks />
      ),
      hasAccess: hasRejectedTasksAccess,
      id: 2,
      label: t("tabs.rejectedTasks")
    },
    {
      component: (
        <CompletedTasks />
      ),
      hasAccess: hasCompletedTasksAccess,
      id: 3,
      label: t("tabs.completedTasks")
    }
  ]
  
  const tabs = allTabs.filter((tab) => tab.hasAccess)

  return (
    <>
      <FilterSection />
      <div className={ styles.subTabContainer }>
        <Tabs
          className={ classNames(styles.tabsContainer, "reportTabs") }
          value={ value }
          onChange={ (_event, newValue) => setValue(newValue) }
        >
          { tabs.map((tab, index) => (
            <Tab key={ tab.id } label={ tab.label } { ...a11yProps(index) } />
          )) }
        </Tabs>
        { tabs.map((tab, index) => (
          <TabPanel
            key={ tab.id }
            className={ styles.tabPanel }
            value={ value }
            index={ index }
          >
            { tab.component }
          </TabPanel>
        )) }
      </div>
    </>
  )
}

export default CertificateProvisioning

const a11yProps = (index) => {
  return {
    "aria-controls": `sub-tasks-tabpanel-${index}`,
    id: `sub-tasks-tab-${index}`
  }
}

import PropTypes from "prop-types"
import React, { createRef, forwardRef, useImperativeHandle, useRef } from "react"
import { useIsFetching } from "react-query"

import ActivityAssetsAccordion from "./ActivityAssetsAccordion"

import ConfigActivityAccordion from "../../../../../components/ConfigActivityAccordion/ConfigActivityAccordion"
import ErrorPanel from "../../../../../components/DataTable/internals/ErrorPanel/ErrorPanel"
import Loading from "../../../../../components/Loading/Loading"
import { TABLE_INSTANCES } from "../../../../../utils/Constants/DataTable"
import styles from "../DeploymentActivities.module.scss"

// eslint-disable-next-line react/display-name
const ConfigActivitiesAccordionItem = forwardRef(({
  t,
  activityDetails,
  onToggleAccordion,
  isLoadingAssets,
  throttleActions,
  handleSelectedAssets,
  assetQueryKey,
  expandAllAccordion
}, ref) => {
  const isFetchingEachAssets = useIsFetching({ queryKey: assetQueryKey })
  const fleets = activityDetails?.fleets
  const isErrorLoadingAssets = activityDetails?.isErrorLoadingAssets

  const assetContainerRef = useRef([])
  assetContainerRef.current = fleets?.map((_, i) => assetContainerRef?.current?.[i] ?? createRef())

  useImperativeHandle(ref, () => ({
    resetCheckboxSelection() {
      resetTableSelection()
    }
  }))
  
  const resetTableSelection = () => assetContainerRef?.current?.forEach((item) => item?.current?.resetSelection(false))

  return (
    <ConfigActivityAccordion
      t={ t }
      id={ activityDetails.jobId }
      isExpanded={ expandAllAccordion ? true : activityDetails?.isExpanded }
      activityDetails={ activityDetails }
      customClass={ styles.accordionCustomClass }
      onChange={ onToggleAccordion }
    >
      <div className={ styles.innerAccordionWrapper }>
        { !!(isFetchingEachAssets || isLoadingAssets) && (
          <Loading showSubMessage={ false } />
        ) }
        { !isLoadingAssets && !isFetchingEachAssets && isErrorLoadingAssets &&
          <ErrorPanel
            type={ TABLE_INSTANCES.CONFIG_DEPLOYMENT_ACTIVITIES }
            queryKey={ assetQueryKey }
            customClass={ styles.assetErrorPanel }
          />
        }
        { !isLoadingAssets && !isFetchingEachAssets && fleets?.map((fleet, i) => (
          <ActivityAssetsAccordion
            key={ activityDetails.jobId + fleet?.assetDetails?.serialNumber }
            isExpanded={ expandAllAccordion ? true : i === 0 }
            fleet={ fleet }
            throttleActions={ throttleActions }
            id={ "" + activityDetails.jobId + "$index-" + i }
            handleSelectedAssets={ handleSelectedAssets }
            ref={ assetContainerRef.current?.[i] }
          />
        )) }
      </div>
    </ConfigActivityAccordion>
  )
})

ConfigActivitiesAccordionItem.propTypes = {
  activityDetails: PropTypes.object,
  assetQueryKey: PropTypes.array,
  expandAllAccordion: PropTypes.bool,
  handleSelectedAssets: PropTypes.func,
  isLoadingAssets: PropTypes.bool,
  onToggleAccordion: PropTypes.func,
  t: PropTypes.any,
  throttleActions: PropTypes.any
}

export default ConfigActivitiesAccordionItem

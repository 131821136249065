/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types"
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from "react"
import { Draggable } from "react-beautiful-dnd"
import { useIsFetching } from "react-query"

import AssetsNotInSubFleetColumnConfig from "./AssetsNotInSubFleetColumnConfig"
import styles from "./AssetsNotInSubFleetContainer.module.scss"

import AssetCard from "../../../../components/AssetCard/AssetCard"
import AssetsNotInFleet from "../../../../components/AssetsNotInFleet/AssetsNotInFleet"
import DataTable from "../../../../components/DataTable/DataTable"
import ErrorPanel from "../../../../components/DataTable/internals/ErrorPanel/ErrorPanel"
import NoRows from "../../../../components/DataTable/internals/NoRows/NoRows"
import Loading from "../../../../components/Loading/Loading"
import { useFleetViewContext } from "../../../../contexts/fleetView/FleetView"
import { CommonAuthElement } from "../../../../utils/Constants/Auth/common"
import { TABLE_INSTANCES } from "../../../../utils/Constants/DataTable"
import useAccess from "../../../../utils/CustomHooks/useAccess"
import useCheckBoxSelection from "../../../../utils/CustomHooks/useCheckBoxSelection"
import { StorageKeys, clearSession, getSession, setSession } from "../../../../utils/SessionHelper/session"
import { classNames } from "../../../../utils/styles/helper"
import { FLEET_ACCESS } from "../../FleetUtils"
import { getItemStyle } from "../fleetViewStyles"

// eslint-disable-next-line react/display-name
const AssetsNotInSubFleetContainer = forwardRef(
  (
    { assetsNotInFleetData: { assets, distributionData, ...assetsInformation }, isDefaultExpanded, handleAddSubFleet },
    ref
  ) => {
    const { toggleAccordionExpand, fleetViewState, setSelectedAssets } = useFleetViewContext()
    const tableRef = useRef(null)
    const hasSelectAccess = useAccess(CommonAuthElement.Any)
    const hasDragAccess = useAccess(FLEET_ACCESS.moveAsset)

    const subFleetsCount = fleetViewState.subFleets?.length ?? 0
    const {
      allRowsSelected,
      selectedRows: selectedAssets,
      handleSelectRow,
      handleSelectAll,
      resetSelection,
      setDefaultSelectedRows
    } = useCheckBoxSelection({
      data: assets,
      onReset: () => setSelectedAssets([]),
      onSelectAllCallback: (data) => {
        setSelectedAssets(data)
        if (data.length === 0) {
          tableRef?.current?.toggleAll?.(false)
        } else {
          tableRef?.current?.toggleAll?.(true)
        }
      },
      onSelectRowCallback: (selectedRows) => setSelectedAssets(selectedRows)
    })

    useImperativeHandle(ref, () => ({
      resetSelection() {
        tableRef?.current?.toggleAll(false)
        resetSelection()
      }
    }))

    useEffect(() => {
      const fleetGroupByCreateFleet = getSession(StorageKeys.FLEET_CREATE_FLEET)
      const cachedAssets = getSession(StorageKeys.FLEET_GROUP_SELECTED_ASSETS)
      const groupBySelectedAssets = cachedAssets ? JSON.parse(cachedAssets) : null
      if (fleetGroupByCreateFleet) {
        if (groupBySelectedAssets) {
          setSelectedAssets(groupBySelectedAssets)
          setDefaultSelectedRows(groupBySelectedAssets)
          tableRef.current?.toggleRowSelect(groupBySelectedAssets)
        }
        handleAddSubFleet(true, groupBySelectedAssets)
        setSession(StorageKeys.FLEET_CREATE_FLEET_REF, JSON.stringify(true))
      } else {
        setDefaultSelectedRows(fleetViewState.selectedAssets)
        tableRef.current?.toggleRowSelect(fleetViewState.selectedAssets)
      }
      clearSession(StorageKeys.FLEET_CREATE_FLEET)
      clearSession(StorageKeys.FLEET_GROUP_SELECTED_ASSETS)
    }, [])

    let parentCheckboxState = ""
    if (allRowsSelected) parentCheckboxState = "checked"
    else if (selectedAssets.length > 0) parentCheckboxState = "indeterminate"

    const nowRows = useCallback(Boolean(assets?.length === 0))
    const isFetchingAssets = useIsFetching({ queryKey: fleetViewState.assetsQueryKey })

    const getAssetsCard = () => {
      if (fleetViewState.assetsQueryKey && isFetchingAssets) {
        return <Loading customStyles={ styles.loader } />
      } else if (fleetViewState.isAssetError) {
        return <ErrorPanel 
          type={ TABLE_INSTANCES.ASSETS_NOT_IN_SUB_FLEET } 
          queryKey={ fleetViewState.assetsQueryKey } 
        />
      } else if (nowRows) {
        return <NoRows type={ TABLE_INSTANCES.ASSETS_NOT_IN_SUB_FLEET } />
      } else {
        return (
          <div className={ styles.notInSubFleetCardContainer }>
            { assets?.map((asset, index) => (
              <Draggable index={ index } key={ asset.id } draggableId={ asset.serialNumber }>
                { (provided, snapshot) => (
                  <>
                    <div
                      ref={ provided.innerRef }
                      { ...provided.draggableProps }
                      style={ getItemStyle(snapshot.isDragging, provided.draggableProps.style) }
                    >
                      <AssetCard
                        isSelectable
                        assetDetails={ asset }
                        isSelected={ selectedAssets.some(({ id }) => asset.id === id) }
                        key={ asset.id }
                        dndProvided={ Boolean(subFleetsCount > 0) && provided }
                        onChange={ handleSelectRow }
                        status={ asset.status }
                      />
                    </div>
                    { snapshot.isDragging && (
                      <div className={ styles.draggingAssetPlaceHolder }>
                        <AssetCard isSelectable assetDetails={ asset } key={ asset.id } status={ asset.status } />
                      </div>
                    ) }
                  </>
                ) }
              </Draggable>
            )) }
          </div>
        )
      }
    }

    return (
      <AssetsNotInFleet
        isSelectable={ hasSelectAccess }
        isExpanded={ isDefaultExpanded }
        isChecked={ parentCheckboxState }
        onChange={ toggleAccordionExpand }
        onSelect={ handleSelectAll }
        assetsInformation={ {
          ...assetsInformation,
          label: assetsInformation.name
        } }
        distributionData={ distributionData }
        customClass={ classNames(styles.accordionCustomClass, "AssetsNotInSubFleet") }
        displayBars={ true }
        displayLabelText={ true }
      >
        <div className={ styles.notInSubFleetContainer }>
          { fleetViewState.isCardView ? (
            getAssetsCard()
          ) : (
            <DataTable
              displayRowColorBar
              rowHoverEffect
              tableData={ assets }
              internalSelectAll
              highlightSelectedRows
              selectedRows={ selectedAssets }
              selectableRows={ hasSelectAccess }
              ref={ tableRef }
              columnsData={ AssetsNotInSubFleetColumnConfig() }
              onSelectRowCallBack={ ({ checked, data }) => handleSelectRow(checked, data) }
              onSelectAllCallBack={ (checked) => handleSelectAll(checked) }
              draggableRows={ Boolean(subFleetsCount > 0) && hasDragAccess }
              type={ TABLE_INSTANCES.ASSETS_NOT_IN_SUB_FLEET }
              isError={ fleetViewState.isAssetError }
              queryKey={ fleetViewState.assetsQueryKey }
            />
          ) }
        </div>
      </AssetsNotInFleet>
    )
  }
)
AssetsNotInSubFleetContainer.propTypes = {
  assetsNotInFleetData: PropTypes.shape({
    assets: PropTypes.array,
    distributionData: PropTypes.array
  }),
  handleAddSubFleet: PropTypes.func,
  isDefaultExpanded: PropTypes.bool
}

const arePropsEqual = (prevProps, nextProps) => {
  return (
    JSON.stringify(prevProps.assetsNotInFleetData) === JSON.stringify(nextProps.assetsNotInFleetData) &&
    prevProps.isDefaultExpanded === nextProps.isDefaultExpanded
  )
}

export default React.memo(AssetsNotInSubFleetContainer, arePropsEqual)

import { addDays, subDays } from "date-fns"
import i18n from "i18next"

import { fileSVC, fileSVCKeys } from "../../../../services/reactQueries/filesvc"
import { TABLE_ACTION } from "../../../../utils/Constants/AssetsConfig"
import { EMPTY_CELL_DEFAULT_VALUE } from "../../../../utils/Constants/DataTable"

const todaysDate = new Date()

export const DEFAULT_DATE_RANGE = {
  endDate: addDays(todaysDate, 0),
  maxDate: addDays(todaysDate, 0),
  minDate: subDays(todaysDate, 364),
  startDate: subDays(todaysDate, 29)
}

export const RETRY_BUTTON_STATUSES = ["rejected", "cancelled", "canceled", "failed"]

export const INPROGRESS_STATUSES = ["in install", "ininstall", "in-install", "in download", "indownload", "in-download"]

export const getHasAnyActionAvailable = (items) => {
  const hasSomeRetryActions = items?.some(
    (item) => RETRY_BUTTON_STATUSES.includes(item?.status?.toLowerCase()) && !item?.isRemoved
  )
  const hasSomeCancelActions = items?.some((item) => INPROGRESS_STATUSES.includes(item?.status?.toLowerCase()))
  return hasSomeRetryActions || hasSomeCancelActions
}

const getConfirmMessages = (action, payload) => {
  const key = action === TABLE_ACTION.RETRY_CENTRAL_DEPLOYMENT ? "retry" : "cancel"
  const i18nKey = "configuration:centralConfigurations.deploymentActivities.confirmPopup"
  const heading = i18n.t(`${i18nKey}.${key}.heading`)
  let message = ""
  let warning = ""
  if (payload?.length > 1) {
    message = i18n.t(`${i18nKey}.${key}.multipleAssetsMessage`, {
      fileName: key === "retry" ? payload[0]?.fileName : payload?.length
    })
    warning = key === "retry" && "One or more assets already has a Configuration file deployment in progress."
  } else {
    message = i18n.t(`${i18nKey}.${key}.singleAssetsMessage`, {
      fileName:
        key === "retry" ? payload[0]?.fileName : payload[0]?.assetName
    })
    warning = key === "retry" && "This asset already has a Configuration file deployment in progress"
  }
  return { heading, message, warning }
}

export const getRetryConfirmOverlayParams = (action, payload, isHeaderAction) => {
  const { message, heading, warning } = getConfirmMessages(action, payload)
  const queryKey = [fileSVCKeys.GET_DEPLOY_ACTIVITY_ASSETS, payload?.[0]?.jobId]

  return {
    activityQueryKey: queryKey,
    fileName: payload?.[0]?.fileName,
    fromCentral: true,
    heading,
    isHeaderAction,
    isOpen: true,
    isRetry: true,
    jobId: payload?.[0]?.jobId,
    message,
    selectedAssets: payload,
    selectedFileId: payload?.[0]?.fileId,
    type: action,
    warning
  }
}

export const getCancelConfirmOverlayParams = (action, payload, isHeaderAction) => {
  const { message, heading } = getConfirmMessages(action, payload)
  const queryKey = [fileSVCKeys.GET_DEPLOY_ACTIVITY_ASSETS, payload?.[0]?.jobId]

  return {
    activityQueryKey: queryKey,
    fileName: payload?.[0]?.fileName,
    fromCentral: true,
    heading,
    isHeaderAction,
    isOpen: true,
    message,
    selectedAssets: payload,
    selectedFileId: payload?.[0]?.jobId,
    type: action
  }
}

export const assignedJobandFileIdToAssets = (assets, activityData) => {
  assets?.forEach((asset) => {
    asset["id"] = "" + asset.serialNumber + activityData?.jobId + activityData?.activity?.fileId
    asset["jobId"] = activityData?.jobId
    asset["fileId"] = activityData?.activity?.fileId
    asset["isRemoved"] = activityData?.activity?.isRemoved
    asset["fileName"] = activityData?.activity?.fileName
    asset["subFleet"] = asset.subFleet || EMPTY_CELL_DEFAULT_VALUE
    asset["status"] = asset?.status?.toLowerCase()
  })
  return assets
}

const mergeFleetAndDiscreteAssets = (data) => {
  let fleetsData = data?.data?.fleets ? [...data.data.fleets] : []
  let discreteAssets = data?.data?.discreteAssets ? [...data.data.discreteAssets] : []

  if (fleetsData?.length > 0) fleetsData = fleetsData.filter((fleet) => fleet?.assetDetails?.length > 0)
  if (discreteAssets?.length > 0) {
    fleetsData.push({
      assetDetails: discreteAssets,
      fleetName: i18n.t("configuration:centralConfigurations.deploymentActivities.labels.assetsNotInFleet")
    })
  }
  return fleetsData
}

const transformActivityForEachAssetsData = (data, deploymentActivitiesData, hasAnyActionAccess) => {
  const fleetsData = mergeFleetAndDiscreteAssets(data)
  fleetsData.forEach((fleet) => {
    fleet.assetDetails = assignedJobandFileIdToAssets(fleet?.assetDetails, deploymentActivitiesData)
    fleet["hasAnyActionAccess"] = getHasAnyActionAvailable(fleet?.assetDetails) && hasAnyActionAccess
  })
  return fleetsData
}

export const fetchAssetsForEachActivity = (queryClient, data, siteUcmId, searchInput, hasAnyActionAccess) => {
  data?.forEach(async (item) => {
    const queryKey = [fileSVCKeys.GET_DEPLOY_ACTIVITY_ASSETS, item?.jobId]
    try {
      const data = await queryClient.fetchQuery(queryKey, () =>
        fileSVC.getDeploymentActivityAssets(siteUcmId, item?.jobId, searchInput)
      )
      item.isErrorLoadingAssets = false
      item.fleets = transformActivityForEachAssetsData(data, item, hasAnyActionAccess)
    } catch (e) {
      item.isErrorLoadingAssets = true
    }
  })
  return data
}

export const transformActivityAssetsData = (data, deploymentActivities, expandedJobId, hasAnyActionAccess) => {
  const fleetsData = mergeFleetAndDiscreteAssets(data)
  const deploymentActivitiesData = [...deploymentActivities]
  const idx = deploymentActivitiesData?.findIndex((item) => item.jobId === expandedJobId)
  if (idx > -1) {
    fleetsData.forEach((fleet) => {
      fleet.assetDetails = assignedJobandFileIdToAssets(fleet?.assetDetails, deploymentActivitiesData[idx])
      fleet["hasAnyActionAccess"] = getHasAnyActionAvailable(fleet?.assetDetails) && hasAnyActionAccess
    })
    deploymentActivitiesData[idx].isErrorLoadingAssets = false
    deploymentActivitiesData[idx].fleets = fleetsData
  }
  return deploymentActivitiesData
}

export const resetAccordionExpandedState = (data, id) => {
  const newIdx = data.findIndex((item) => item?.jobId === id)
  const oldIdx = data.findIndex((item) => item?.isExpanded)
  if (oldIdx > -1) data[oldIdx].isExpanded = false
  if (newIdx > -1) data[newIdx].isExpanded = true
  return data
}

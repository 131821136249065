import i18n from "i18next"
import React from "react"

import { TABLE_COLUMN_SPECIAL_ID_ACCESSORS } from "../../../../../../../utils/Constants/DataTable"
import { DATE_FORMAT } from "../../../../../../../utils/Constants/TimeZones"
import useIsCustomerRoute from "../../../../../../../utils/CustomHooks/useIsCustomerRoute"
import useIsSiteRoute from "../../../../../../../utils/CustomHooks/useIsSiteRoute"
import { formatDate } from "../../../../../../../utils/helper"
import { resolutionSizeObject } from "../../../../../../SiteView/FleetsSummary/internals/GroupByAssetsFleetColumnsData"
import AssetInfo from "../../../internals/AssetInfo"

const PendingTasksColumnConfig = () => {
  const isCustomerRoute = useIsCustomerRoute()
  const isSiteRoute = useIsSiteRoute()

  return [
    {
      accessorKey: "serialNumber",
      header: i18n.t("tasks:pendingTasks.table.headerLabels.serialNumber")
    },
    {
      accessorKey: "assetName",
      header: i18n.t("tasks:pendingTasks.table.headerLabels.name")
    },
    {
      accessorKey: "model",
      header: i18n.t("tasks:pendingTasks.table.headerLabels.model")
    },
    {
      accessorKey: "modality",
      header: i18n.t("tasks:pendingTasks.table.headerLabels.modality")
    },
    {
      accessorKey: "customerName",
      header: i18n.t("tasks:pendingTasks.table.headerLabels.customer"),
      show: !isCustomerRoute
    },
    {
      accessorKey: "siteName",
      header: i18n.t("tasks:pendingTasks.table.headerLabels.site"),
      show: !isSiteRoute
    },
    {
      accessorKey: "createdDateTime",
      cell: ({ cell }) => formatDate(cell.getValue(), DATE_FORMAT.dateTime),
      defaultCanSort: true,
      header: i18n.t("tasks:pendingTasks.table.headerLabels.createdDate"),
      sortDesc: true,
      ...resolutionSizeObject[TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_LAST_CONNECTED]
    },
    {
      accessorKey: "info",
      cell: ({ row }) => (
        <AssetInfo row={ row }/>
      ),
      disableTooltip: true,
      enableResizing: false,
      enableSorting: false, 
      header: i18n.t("tasks:pendingTasks.table.headerLabels.info"),
      minSize: 40,
      size: 40
    }
  ]
}

export default PendingTasksColumnConfig

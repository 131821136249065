import { Button } from "@mui/material"
import React, { useEffect, useReducer, useState } from "react"
import { useTranslation } from "react-i18next"

import styles from "./GatewayDeployment.module.scss"
import { fields, formReducer, initialState, transformData } from "./gatewayDeploymentUtils"

import Icon from "../../../../components/Icon/Icon"
import { useToaster } from "../../../../contexts/ToastContext"
import { clustercreationsvc } from "../../../../services/reactQueries/clustercreationsvc"
import { GATEWAY_ERROR_CODE } from "../../../../utils/Constants/ErrorCodes"
import { useMutationWithHandlers } from "../../../../utils/CustomHooks/reactQuery"
import { ICON_NAME } from "../../../../utils/GlobalConstants"
import LabelField from "../../internal/LabelField"

const GatewayDeployment = () => {
  const { t } = useTranslation(["tasks"])
  const [state, dispatch] = useReducer(formReducer, initialState)
  const [isResetEnabled, setIsResetEnabled] = useState(false)
  const { displayMediumErrorToast, displaySuccessToast } = useToaster()

  useEffect(() => {
    setIsResetEnabled(JSON.stringify(state) !== JSON.stringify(initialState))
  }, [state])

  const onDownloadTriggeredSuccessfull = () => {
    displaySuccessToast(t("tabs.messages.successMessage"))
  }
  const onDownloadTriggeredFailed = (error) => {
    if (error?.data?.error?.code === GATEWAY_ERROR_CODE.INVALID_SERIAL_NUMBER) {
      displayMediumErrorToast(t("tabs.messages.invalidSerialNumber"))
    } else if (error?.data?.error?.code === GATEWAY_ERROR_CODE.HOST_NOT_FOUND) {
      displayMediumErrorToast(t("tabs.messages.edgeHostError"))
    }else {
      displayMediumErrorToast(t("tabs.messages.errorMessage"))
    }
  }

  const { requestMutation: createCluster, isLoading } = useMutationWithHandlers({
    onCompletedCallback: onDownloadTriggeredSuccessfull,
    onErrorCallback: onDownloadTriggeredFailed,
    queryFn: clustercreationsvc.createCluster,
    refetchQueries: ["clustercreation", state.serialNumber]
  })

  const handleOnChange = (field) => (value) => {
    dispatch({ field, type: "UPDATE_FIELD", value })
  }

  const handleResetForm = () => {
    dispatch({ type: "RESET_FORM" })
  }

  const handelSubmit = () => {
    createCluster({
      payload: {
        variables: transformData(state)
      }
    })
  }

  const isFormValid = fields.every(({ key, required }) => !required || state[key].trim() !== "")

  return (
    <div>
      <table className={ styles.gatewayTable }>
        <tbody>
          { fields.map(({ label, key, required, type }) => (
            <LabelField
              key={ label }
              label={ label }
              isRequired={ required }
              value={ state[key] }
              type={ type }
              inputType={ type }
              onChange={ handleOnChange(key) }
            />
          )) }
        </tbody>
      </table>
      <div className={ styles.buttonGroup }>
        <Button onClick={ handleResetForm } variant="outlined" disabled={ !isResetEnabled } data-testid="resetButton">
          { t("tabs.gatewayDeploymentButtons.reset") }
        </Button>
        <span className={ styles.buttonContainer }>
          <Button
            variant="contained"
            color="primary"
            size="large"
            data-testid="confirmButton"
            onClick={ handelSubmit }
            disabled={ !isFormValid }
          >
            { isLoading ? (
              <Icon
                icon={ ICON_NAME.btnLoader }
                iconStyles={ styles.loadingIcon }
              />
            ) : 
              t("tabs.gatewayConfigurationButtons.generate") 

            }
          </Button>
        </span>
      </div>
    </div>
  )
}

export default GatewayDeployment

export const transformAssets = (assets) => {
  assets?.forEach((asset) => {
    asset["id"] = "" + asset.serialnumber
    asset["serialNumber"] = "" + asset.serialnumber
  })
  return assets
}

export const getAssetSelectionText = (searchResultCount, searchError, t) => {
  let selectionText = ""
  if (searchError) return ""
  if (searchResultCount === 0) {
    selectionText = t("completedTasks.headSection.noSearchResult")
  } else if (searchResultCount > 0) {
    const key = searchResultCount > 1 ? "searchAssetsCount" : "singleSearchAssetsCount"
    const val = `completedTasks.headSection.${key}`
    selectionText = `${t(val, {
      searchResultsCount: searchResultCount
    })}`
  }
  return selectionText
}

import PropTypes from "prop-types"
import React from "react"

import styles  from "./ErrorMessage.module.scss"

import { ICON_NAME } from "../../utils/GlobalConstants"
import { classNames } from "../../utils/styles/helper"
import Icon from "../Icon/Icon"

const ErrorMessage = ({ error, errorMessageStyle, icon }) => {
  return (
    <div className={ classNames(styles.errorMessage, errorMessageStyle) } >
      { icon && 
        <span>
          <Icon icon={ ICON_NAME.error } />
        </span>
      }
      <span>
        { error }
      </span>
    </div>
  )
}

ErrorMessage.propTypes = {
  error: PropTypes.any,
  errorMessageStyle: PropTypes.string,
  icon: PropTypes.any
}

export default ErrorMessage

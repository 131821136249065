export const ConfigAuthElement = {
  CancelDeployConfig:"cancel:asset:config",
  DeleteAssetConfig: "delete:asset:config",
  DemoteAssetConfig: "demote:asset:config",
  DemoteCentralConfig: "demote:central:config",
  DeployAssetConfig: "deploy:asset:config",
  DeployCentralConfig: "deploy:central:config",
  LockAssetConfig: "lock:asset:config",
  PromoteAssetConfig: "promote:asset:config",
  ReadAssetConfigTab: "read:asset:config",
  ReadCentalConfigDeployPage: "deploy:central:config",
  ReadCentalConfigPage: "read:central:config",
  ReadPromotedConfigTab: "read:central:config",
  RequestAssetConfig: "request:asset:config",
  UnlockAssetConfig: "unlock:asset:config"
}

export const DeploymentActivityAuthElement = {
  CentralCancelDeployment: "cancel:central:config",
  CentralRetryDeployment: "deploy:central:config",
  ReadDeploymentActivitiesTab: "Any"
}

import { pkiBusiness } from "../../config/serverConfig"
import { APPROVE, REJECT, REQUEUE } from "../../utils/GlobalConstants"
import axiosClient, { get, patch, post } from "../axios/axios"

const SCROLL_PAGE_SIZE = 12
export const TASKS_COUNT_PAGE_SIZE = 9

const TASKS_STATUS = {
  Completed: "approved",
  Pending: "pending",
  Rejected: "rejected"
}

export const DPSGetSVC = {
  getCompletedTasks: (currPage, { variables }) => {
    const query = "/deviceprovisioningsvc/v1/provisioning-records/retrieve"
    const queryParams = `?page=${currPage}&size=${SCROLL_PAGE_SIZE}`
    const sortParams = "&sortBy=approvedDateTime&sortDirection=DESC"
    
    const requestBody = {
      ...variables,
      status: TASKS_STATUS.Completed
    }
    
    return axiosClient(post(query + queryParams + sortParams, requestBody))
  },
  getCustomers: () => {
    return axiosClient(get("/deviceprovisioningsvc/v1/customers"))
  },
  getModalities: (filterValues) => {
    const params = {
      customerId: filterValues.customerId,
      siteIds: filterValues.siteIds
    }
    return axiosClient(post("/deviceprovisioningsvc/v1/modalities/retrieve", params))
  },
  getModels: (filterValues) => {
    const params = {
      customerId: filterValues.customerId,
      modalities: filterValues.modalities,
      siteIds: filterValues.siteIds
    }
    return axiosClient(post("/deviceprovisioningsvc/v1/models/retrieve", params))
  },
  getPendingTasks: (currPage, { variables }) => {
    const query = "/deviceprovisioningsvc/v1/provisioning-records/retrieve"
    const queryParams = `?page=${currPage}&size=${SCROLL_PAGE_SIZE}`
    const sortParams = "&sortBy=createdDateTime&sortDirection=DESC"
    
    const requestBody = {
      ...variables,
      status: TASKS_STATUS.Pending
    }
    
    return axiosClient(post(query + queryParams + sortParams, requestBody))
  },
  getPendingTasksCount: () => {
    const query = "/deviceprovisioningsvc/v1/provisioning-records/retrieve"
    const queryParams = `?page=1&size=${TASKS_COUNT_PAGE_SIZE}`
    return axiosClient(post(query + queryParams, {
      business: pkiBusiness,
      status: TASKS_STATUS.Pending
    }))
  },
  getRejectedTasks: (currPage, { variables }) => {
    const query = "/deviceprovisioningsvc/v1/provisioning-records/retrieve"
    const queryParams = `?page=${currPage}&size=${SCROLL_PAGE_SIZE}`
    const sortParams = "&sortBy=rejectedDateTime&sortDirection=DESC"
    
    const requestBody = {
      ...variables,
      status: TASKS_STATUS.Rejected
    }
    
    return axiosClient(post(query + queryParams + sortParams, requestBody))
  },
  getSites: (filterValues) => {
    const customerId = filterValues.customerId
    const query = customerId ? `?customerId=${customerId}` : ""
    return axiosClient(get("/deviceprovisioningsvc/v1/sites" + query))
  },
  tasksActionCall: ({ variables, type }) => {
    let query = "/deviceprovisioningsvc/v1/provisioning-records"
    if (type === APPROVE) query += "/approve"
    else if (type === REQUEUE) query += "/reQueue"
    else if (type === REJECT) query += "/reject"
  
    return axiosClient(patch(query, variables))
  }
}

export const DPSGetKeys = { 
  GET_COMPLETED_TASKS: "getCompletedTasks",
  GET_CUSTOMERS_DROPDOWN: "getCustomersDropdown",
  GET_MODALITY_DROPDOWN: "getModalityDropdown",
  GET_MODELS_DROPDOWN: "getModelsDropdown",
  GET_PENDING_TASKS: "getPendingTasks",
  GET_PENDING_TASKS_COUNT: "getPendingTasksCount",
  GET_REJECTED_TASKS: "getRejectedTasks",
  GET_SITES_DROPDOWN: "getSitesDropdown"
}

import { AssetAuthElement } from "./asset"
import { ConfigAuthElement } from "./configuration"
import { CustomerAuthElement } from "./customer"
import { FaultsAuthElement } from "./faults"
import { FleetAuthElement } from "./fleet"
import { ReportAuthElement } from "./report"
import { SiteAuthElement } from "./site"
import { SoftwareAuthElement } from "./software"
import { SubFleetAuthElement } from "./sub-fleet"
import { TasksAuthElement } from "./tasks"

export const SideNavAuthElement = {
  ReadCentalConfig: ConfigAuthElement.ReadCentalConfigPage,
  ReadCentalFaults: FaultsAuthElement.ReadCentalFaultsPage,
  ReadCentralAssetPage: [
    CustomerAuthElement.ReadCustomerPage,
    SiteAuthElement.ReadSitePage,
    FleetAuthElement.ReadFleetPage,
    SubFleetAuthElement.ReadSubFleetPage,
    AssetAuthElement.ReadAssetPage
  ],
  ReadCentralReport: [ReportAuthElement.ReadConfigReport, ReportAuthElement.ReadSWDistReport],
  ReadCentralSWDist: SoftwareAuthElement.ReadCentalSoftDistPage,
  ReadTasks: [
    TasksAuthElement.ReadPendingTasks,
    TasksAuthElement.ReadRejectedTasks,
    TasksAuthElement.ReadCompletedTasks
  ]
}

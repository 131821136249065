export const initialState = {
  NTPServer: "",
  edgeHostName: "",
  nic: "",
  serialNumber: ""
}

export const fields = [
  { key: "edgeHostName", label: "Edge hostname", required: true },
  { key: "serialNumber", label: "Serial number", required: true },
  { key: "NTPServer", label: "NTPServer", required: true },
  { key: "nic", label: "Network Interface card(NIC)", required: true }
]

export const formReducer = (state, action) => {
  switch (action.type) {
  case "UPDATE_FIELD":
    return { ...state, [action.field]: action.value }
  case "RESET_FORM":
    return initialState
  }
}

export const transformData = (data) => {
  return {
    edgeHost: data?.edgeHostName,
    networkInterfaceCard: data?.nic,
    ntpServer: data?.NTPServer,
    serialNumber: data?.serialNumber
  }
}

/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "@mui/material"
import PropTypes from "prop-types"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"

import AuthGuard from "../../../../../../../components/AuthGuard/AuthGuard"
import Icon from "../../../../../../../components/Icon/Icon"
import SearchBar from "../../../../../../../components/SearchBar/SearchBar"
import { TasksAuthElement } from "../../../../../../../utils/Constants/Auth/tasks"
import { SEARCH_INSTANCES } from "../../../../../../../utils/Constants/Search"
import { ICON_NAME, REQUEUE } from "../../../../../../../utils/GlobalConstants"
import styles from "../RejectedTasks.module.scss"
import { getAssetSelectionText } from "../RejectedTasksUtils"

const HeadSection = ({
  selectedAssets,
  setTasksActionPopUp,
  searchBarRef,
  isSearchDisabled,
  handleSearch,
  searchResultCount,
  searchError
}) => {
  const { t } = useTranslation(["tasks"])

  const enableAction = selectedAssets.length > 0

  const handleRequeueClick = () => {
    setTasksActionPopUp({
      heading: t("rejectedTasks.popup.requeueHeading"),
      isOpen: true,
      selectedRecords: selectedAssets,
      type: REQUEUE
    })
  }

  const assetSelectionText = useMemo(
    () => getAssetSelectionText(selectedAssets, searchResultCount, searchError, t),
    [selectedAssets, searchResultCount, searchError]
  )

  return (
    <div className={ styles.headerContainer }>
      <div className={ styles.rightPanel }>
        <div>{ assetSelectionText }</div>
        <AuthGuard actionType={ TasksAuthElement.RequeueRejectedTasks }>
          <Button
            variant="text"
            color="secondary"
            startIcon={ <Icon icon={ enableAction ? ICON_NAME.refreshBack : ICON_NAME.refreshBackDisabled } /> }
            disabled={ !enableAction }
            onClick={ handleRequeueClick }
          >
            { t("rejectedTasks.headSection.buttons.requeue") }
          </Button>
        </AuthGuard>
        <SearchBar
          type={ SEARCH_INSTANCES.rejectedTasks }
          ref={ searchBarRef }
          isDisabled={ isSearchDisabled }
          onHandleSubmit={ handleSearch }
        />
      </div>
    </div>
  )
}

HeadSection.propTypes = {
  handleSearch: PropTypes.func,
  isSearchDisabled: PropTypes.bool,
  searchBarRef: PropTypes.any,
  searchError: PropTypes.bool,
  searchResultCount: PropTypes.number,
  selectedAssets: PropTypes.array,
  setTasksActionPopUp: PropTypes.func
}

export default HeadSection

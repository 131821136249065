import PropTypes from "prop-types"
import React, { forwardRef, useImperativeHandle, useRef } from "react"

import ConfigActivitiesColumnConfig from "./ConfigActivitiesColumnConfig"

import ConfigActivityAssetsAccordion from 
  "../../../../../components/ConfigActivityAssetsAccordion/ConfigActivityAssetsAccordion"
import DataTable from "../../../../../components/DataTable/DataTable"
import { TABLE_INSTANCES } from "../../../../../utils/Constants/DataTable"
import useCheckBoxSelection from "../../../../../utils/CustomHooks/useCheckBoxSelection"
import styles from "../DeploymentActivities.module.scss"

// eslint-disable-next-line react/display-name
const ActivityAssetsAccordion = forwardRef(({
  id,
  fleet,
  isExpanded,
  throttleActions,
  handleSelectedAssets
}, ref) => {
  const tableRef = useRef(null)
  const tableData = fleet?.assetDetails

  const { selectedRows, handleSelectRow, handleSelectAll, resetSelection } = useCheckBoxSelection({
    data: tableData,
    onReset: () => handleSelectedAssets(id, []),
    onSelectAllCallback: (data) => handleSelectedAssets(id, data),
    onSelectRowCallback: (rows) => handleSelectedAssets(id, rows)
  })

  useImperativeHandle(ref, () => ({
    resetSelection() {
      resetSelection()
      tableRef?.current?.toggleAll(false)
    }
  }))

  return (
    <ConfigActivityAssetsAccordion
      id={ id }
      label={ fleet?.fleetName }
      itemCount={ fleet?.assetDetails?.length }
      isExpanded={ isExpanded }
      customClass={ styles.innerAccordionCustomClass }
    >
      <DataTable
        selectableRows
        internalSelectAll
        highlightSelectedRows
        tableData={ tableData ?? [] }
        rowHoverEffect
        columnsData={
          ConfigActivitiesColumnConfig(throttleActions, fleet?.hasAnyActionAccess)
        }
        selectedRows={ selectedRows }
        onSelectAllCallBack={ handleSelectAll }
        onSelectRowCallBack={ ({ checked, data }) => handleSelectRow(checked, data) }
        type={ TABLE_INSTANCES.CONFIG_DEPLOYMENT_ACTIVITIES }
        ref={ tableRef }
      />
    </ConfigActivityAssetsAccordion>
  )
})

ActivityAssetsAccordion.propTypes = {
  fleet: PropTypes.object,
  handleSelectedAssets: PropTypes.func,
  id: PropTypes.string,
  isExpanded: PropTypes.bool,
  throttleActions: PropTypes.any
}

export default ActivityAssetsAccordion

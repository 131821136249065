export const apiServer = process.env.REACT_APP_API_SERVER
export const pkiBaseURL = process.env.REACT_APP_PKI_API_SERVER
export const pkiBusiness = process.env.REACT_APP_PKI_BUSINESS
export const idamServer = process.env.REACT_APP_IDAM_SERVER
export const isQRCodeEnabled = process.env.REACT_APP_IS_QR_SCAN_REQUIRED
export const rumServerDetails = {
  appID: process.env.REACT_APP_RUM_APP_ID,
  poolID: process.env.REACT_APP_RUM_POOL_ID,
  region: process.env.REACT_APP_RUM_REGION,
  server: process.env.REACT_APP_RUM_SERVER
}
export const fileDetails = {
  faqDocId: process.env.REACT_APP_FAQ_DOC_ID,
  quickgGuideDocId: process.env.REACT_APP_QUICK_GUIDE_DOC_ID
}

export const buildDetails = {
  build: process.env.REACT_APP_BUILD,
  version: process.env.REACT_APP_VERSION
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { useQueryClient } from "react-query"
import { useNavigate, useSearchParams } from "react-router-dom"

import { default as ErrorPage } from "../../../components/Error/Error"
import Loading from "../../../components/Loading/Loading"
import { generateToken } from "../../../services/idam/login"
import { DPSGetKeys, DPSGetSVC } from "../../../services/reactQueries/device-provisioningsvc"
import { fleetGetSVC, fleetSVCKeys } from "../../../services/reactQueries/fleetsvc"
import { ERROR_MESSAGE } from "../../../utils/Auth/auth"
import { ROUTE_CONFIG } from "../../../utils/GlobalConstants"
import { StorageKeys, StorageType, clearAllSession, setSession } from "../../../utils/SessionHelper/session"

const UserAuth = () => {
  const queryClient = useQueryClient()
  const [searchParams] = useSearchParams()
  const [error, setError] = useState()
  const navigate = useNavigate()

  useEffect(() => {
    const authCode = searchParams.get("code")
    const authState = searchParams.get("state")
    if (authCode && authState) {
      getToken(authCode, authState)
    } else {
      handleLogout()
    }
  }, [])

  const getToken = async (authCode, authState) => {
    const { error: err } = await generateToken(authCode, authState)
    if (err) {
      if (err?.message === ERROR_MESSAGE.InvalidState) handleLogout()
      else setError(err)
    } else {
      try {
        let tasksData
        try {
          tasksData = await queryClient.fetchQuery(
            [DPSGetKeys.GET_PENDING_TASKS_COUNT], () => DPSGetSVC.getPendingTasksCount(),
            { retry: false }
          )
        } catch (taskErr) {
          tasksData = null
        }
        const sitesData = await queryClient.fetchQuery(
          [fleetSVCKeys.GET_SITES], fleetGetSVC.getSites
        )
        const customerRes = sitesData?.data?.customer
        const sites = sitesData?.data?.sites
        const tasksCount = tasksData?.data?.pagination?.totalElements ?? null
        handleNavigationResponse(customerRes, sites, tasksCount)
      } catch (error) {
        if (error?.status === 403) handleUnauthorized()
        else setError(error)
      }
    }
  }

  const handleNavigationResponse = (customerRes, sites, tasksCount) => {
    const customerId = customerRes?.id
    const ucmId = customerRes?.ucmId

    const CUSTOMER_ROUTE = `/${ROUTE_CONFIG.CUSTOMER.routeName}/${customerId}`

    setSession(StorageKeys.CUSTOMER_ID, customerId)
    setSession(StorageKeys.CUSTOMER_UCM_ID, ucmId)

    if (sites?.length === 0 && tasksCount === 0) {
      handleUnauthorized()
    } else if (sites?.length === 0 && tasksCount > 0) {
      setSession(StorageKeys.SET_CUST_NAME, customerRes?.aliasName ?? customerRes?.name)
      navigate(CUSTOMER_ROUTE + `/${ROUTE_CONFIG.TASKS.routeName}`)
    } else {
      navigate(CUSTOMER_ROUTE)
    }
  }

  const handleLogout = () => {
    clearAllSession()
    clearAllSession(StorageType.SESSION_STORAGE)
    navigate(`/${ROUTE_CONFIG.LOGIN.routeName}`)
  }

  const handleUnauthorized = () => {
    navigate(`/${ROUTE_CONFIG.ACCESS_DENIED.routeName}`)
  }

  if (error) return <ErrorPage error={ error } />
  return <Loading />
}
  
export default UserAuth

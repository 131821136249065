/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types"
import React, { forwardRef, useImperativeHandle, useRef } from "react"
import { useIsFetching } from "react-query"

import RejectedTasksColumnConfig from "./RejectedTasksColumnConfig"

import DataTable from "../../../../../../../components/DataTable/DataTable"
import { TABLE_INSTANCES } from "../../../../../../../utils/Constants/DataTable"
import useCheckBoxSelection from "../../../../../../../utils/CustomHooks/useCheckBoxSelection"

// eslint-disable-next-line react/display-name
const AssetDetails = forwardRef(({
  queryKey,
  currPage,
  setSelectedAssets,
  tableData,
  paginationData,
  handlePaginationChange,
  customNoRowsMessage
}, ref) => {
  const tableRef = useRef(null)
  const isFetching = useIsFetching({ queryKey })
  
  useImperativeHandle(ref, () => ({
    resetTablePagination: () => {
      tableRef?.current?.resetPagination()
    },
    resetTableSelection: () => {
      resetSelection()
      tableRef?.current?.toggleAll(false)
    }
  }))

  const {
    selectedRows,
    handleSelectRow,
    handleSelectAll,
    resetSelection
  } = useCheckBoxSelection({
    data: tableData,
    onReset: () => setSelectedAssets([]),
    onSelectAllCallback: setSelectedAssets,
    onSelectRowCallback: setSelectedAssets
  })

  return (
    <DataTable
      ref={ tableRef }
      selectableRows
      rowHoverEffect
      highlightSelectedRows
      selectedRows={ selectedRows }
      internalSelectAll
      tableData={ isFetching && currPage === 1 ? [] : tableData }
      columnsData={ RejectedTasksColumnConfig() }
      onSelectAllCallBack={ handleSelectAll }
      onSelectRowCallBack={ ({ checked, data }) => handleSelectRow(checked, data) }
      type={ TABLE_INSTANCES.REJECTED_TASKS }
      customNoRowsMessage={ customNoRowsMessage }
      queryKey={ queryKey }
      enableScrollPagination
      handleSortPaginationChange={ handlePaginationChange }
      paginationData={ paginationData }
    />
  )
})

AssetDetails.propTypes = {
  currPage: PropTypes.number,
  customNoRowsMessage: PropTypes.string,
  handlePaginationChange: PropTypes.func, 
  paginationData: PropTypes.object,
  queryKey: PropTypes.any,
  setSelectedAssets: PropTypes.func,
  tableData: PropTypes.array
}

export default AssetDetails

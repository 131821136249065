import { addMinutes, addSeconds, isEqual, setHours, setMilliseconds, setMinutes, setSeconds } from "date-fns"

import { BREADCRUMB_ROUTE_ID } from "../../components/Breadcrumb/BreadcrumbUtils"
import { ROUTE_CONFIG, SEARCH_DEFAULT_MIN_CHARACTERS } from "../GlobalConstants"

export const getApplicationRoutes = (queryParams) => {
  const appRoutes = []
  let route = ""
  let i = 1
  for (const [key, value] of Object.entries(BREADCRUMB_ROUTE_ID)) {
    const queryId = queryParams[value]
    if (queryId) {
      route = route + "/" + ROUTE_CONFIG[key].routeName + "/" + queryParams[value]
      appRoutes.push({
        id: i++,
        key: key,
        paramId: queryId,
        route: route
      })
    }
    if (window.location.pathname === "/" + ROUTE_CONFIG[key].routeName) break
  }
  return appRoutes
}

export const generateUUID = () => {
  return crypto.randomUUID()
}

export const decodeURIString = (str) => {
  let formatedStr
  try {
    formatedStr = str ? decodeURIComponent(str) : str
  } catch(e) {
    formatedStr = str
  }
  return formatedStr
}

export const encodeURIString = (str) => {
  let formatedStr
  try {
    formatedStr = str ? encodeURIComponent(str) : str
  } catch(e) {
    formatedStr = str
  }
  return formatedStr
}

export const getFormatedTimeAMPMString = (date) => {
  const newDate = addMinutes(new Date(date), 30)
  let hour = newDate.getHours()
  let minute = newDate.getMinutes()
  minute = Math.ceil(minute / 10) * 10
  if (minute >= 60) {
    if (hour >= 24) {
      hour = 0
      minute = 10
    } else {
      hour = hour + 1
      minute = 0
    }
  }
  if (hour < 10) hour = "0" + hour
  if (minute < 10) minute = "0" + minute
  return `${hour}:${minute}`
}

export const getListOfTimeArray = () => {
  let timeArray = []
  let hours, minutes
  for(let i = 0; i <= 1435; i += 10) {
    hours = Math.floor(i / 60)
    minutes = i % 60
    if (hours < 10){
      hours = "0" + hours
    }
    if (minutes < 10){
      minutes = "0" + minutes
    }
    timeArray.push(hours + ":" + minutes)
  }
  return timeArray
}

export const validateDeployTime = (updatedDate, timeValue, time24HR) => {
  const _updatedDate = new Date(updatedDate).setHours(0, 0, 0, 0)
  const todaysDate = new Date().setHours(0, 0, 0, 0)
  if (!timeValue || timeValue == "Invalid Date" || time24HR?.length < 5) {
    return false
  } else if (_updatedDate === todaysDate) {
    const validTime = addSeconds(new Date(), (30*60))
    const selectedTime = new Date(timeValue)
    const newValidTime = setMilliseconds(
      setSeconds(
        setMinutes(
          setHours(
            new Date(),
            validTime.getHours()),
          validTime.getMinutes()),
        0),
      0)
    const newSelectedTime = setMilliseconds(
      setSeconds(
        setMinutes(
          setHours(new Date(),
            selectedTime.getHours()),
          selectedTime.getMinutes()),
        0),
      0)
    return isEqual(newValidTime, newSelectedTime) || newSelectedTime >= newValidTime
  } else if (_updatedDate < todaysDate) {
    return false
  } else {
    return true
  }
}

export const handleSearchSubmitFn = (formatedValue, closeAction, searchBy, minCharacters, onHandleSubmit) => {
  if (closeAction) {
    onHandleSubmit?.(formatedValue, true, false, searchBy)
  } else if (
    formatedValue.length < (minCharacters || SEARCH_DEFAULT_MIN_CHARACTERS)
  ) {
    onHandleSubmit?.(formatedValue, false, true, searchBy)
  } else {
    onHandleSubmit?.(formatedValue, false, false, searchBy)
  }
}

export const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0
}

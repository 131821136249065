export const initialTasksActionState = {
  heading: "",
  isOpen: false,
  selectedRecords: [],
  type: ""
}

export const transformAssets = (assets) => {
  assets?.forEach((asset) => {
    asset["id"] = "" + asset.serialnumber
    asset["serialNumber"] = "" + asset.serialnumber
  })
  return assets
}

export const getAssetSelectionText = (selectedAssets, searchResultCount, searchError, t) => {
  let selectionText = ""
  if (searchError) return ""
  if (searchResultCount === 0) {
    selectionText = t("rejectedTasks.headSection.noSearchResult")
  } else if (searchResultCount > 0) {
    if (selectedAssets?.length > 0) {
      selectionText = `${t("rejectedTasks.headSection.selectedSearchAssetCount", {
        searchResultsCount: searchResultCount,
        selectedCount: selectedAssets.length
      })}`
    } else {
      const key = searchResultCount > 1 ? "searchAssetsCount" : "singleSearchAssetsCount"
      const val = `rejectedTasks.headSection.${key}`
      selectionText = `${t(val, {
        searchResultsCount: searchResultCount
      })}`
    }
  } else if (selectedAssets?.length > 0) {
    const key = selectedAssets.length > 1 ? "selectedAssetCountText" : "singleSelectedAssetCountText"
    selectionText =
      t(`rejectedTasks.headSection.${key}`, {
        count: selectedAssets?.length
      })
  }
  return selectionText
}

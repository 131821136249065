import { useParams } from "react-router-dom"

import { ROUTE_CONFIG } from "../GlobalConstants"

const useIsCustomerRoute = () => {
  const queryParams = useParams()
  return !!queryParams[ROUTE_CONFIG.CUSTOMER.param]
}

export default useIsCustomerRoute

import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { useParams } from "react-router-dom"

import MenuListItem from "./MenuListItem/MenuListItem"
import styles from "./SideNavigation.module.scss"
import { sideNavigationMenu } from "./SideNavigationUtils"

import { useApp } from "../../contexts/app/AppContext"
import { TASKS_COUNT_PAGE_SIZE } from "../../services/reactQueries/device-provisioningsvc"
import useExpandCollapse from "../../utils/CustomHooks/useExpandCollapse"
import usePendingTasksCount from "../../utils/CustomHooks/usePendingTasksCount"
import { ROUTE_CONFIG } from "../../utils/GlobalConstants"
import { classNames } from "../../utils/styles/helper"
import AuthGuard from "../AuthGuard/AuthGuard"

const SideNavigation = ({ onSelectMenuItem, onCollapseExpand, customStyles }) => {
  const queryParams = useParams()
  const CUSTOMER_ROUTE = `/${ROUTE_CONFIG.CUSTOMER.routeName}/${queryParams[ROUTE_CONFIG.CUSTOMER.param]}`
  const TASKS_ROUTE = CUSTOMER_ROUTE + `/${ROUTE_CONFIG.TASKS.routeName}`
  const [isExpanded, setIsExpanded] = useExpandCollapse(false)
  const currentURL = window.location.pathname
  const menuItems = JSON.parse(JSON.stringify(sideNavigationMenu))
  const { showSiteNavigator } = useApp()
  const pendingTasksCount = usePendingTasksCount()
  const pendingTasksCountLabel = pendingTasksCount > TASKS_COUNT_PAGE_SIZE ?
    `${TASKS_COUNT_PAGE_SIZE}+` :
    pendingTasksCount

  let foundActiveLink = true
  const idx = menuItems.findIndex(
    (menuItem) =>
      menuItem.routePath !== `/${ROUTE_CONFIG.CUSTOMER.routeName}` && currentURL.includes(menuItem.routePath)
  )

  if (idx > -1) menuItems[idx].isActive = true
  else foundActiveLink = false

  const handleSelectMenuItem = ({ id, routePath, title }) => {
    if (onSelectMenuItem) onSelectMenuItem({ id, routePath, title })
  }

  useEffect(() => {
    if (onCollapseExpand) onCollapseExpand(isExpanded)
  }, [isExpanded, onCollapseExpand])

  const hideMenuItems = currentURL === CUSTOMER_ROUTE || currentURL === TASKS_ROUTE

  return (
    <nav aria-label="Side Navigation" className={ styles.sideBarCollapse }>
      <div
        className={ classNames(styles.sidebar, customStyles, 
          isExpanded ? styles.sideBarExpand : styles.sideBarCollapse) }
        onMouseEnter={ () => !showSiteNavigator && setIsExpanded(true) }
        onMouseLeave={ () => setIsExpanded(false) }
        id="sidebar"
        role={ "list" }
      >
        { menuItems.map((menuItem) => (
          <AuthGuard key={ menuItem.id } actionType={ menuItem.authType }>
            { !(hideMenuItems && (menuItem.translationKey !== "assets" && menuItem.translationKey !== "tasks")) && (
              <MenuListItem
                isActive={ (!foundActiveLink && menuItem.translationKey === "assets") || menuItem.isActive }
                menuItem={ menuItem }
                isMenuExpanded={ isExpanded }
                onClickMenuItem={ handleSelectMenuItem }
                showCount={ pendingTasksCount > 0 && menuItem.translationKey === "tasks" }
                countValue={ pendingTasksCountLabel }
              />
            ) }
          </AuthGuard>
        )) }
      </div>
    </nav>
  )
}

SideNavigation.propTypes = {
  customStyles: PropTypes.any,
  onCollapseExpand: PropTypes.func,
  onSelectMenuItem: PropTypes.func
}

SideNavigation.defaultProps = {
  customStyles: ""
}

export default SideNavigation

import i18n from "i18next"

import { APPROVE, REQUEUE } from "../../utils/GlobalConstants"

export const getSerialNumbersList = (data) => {
  return data?.map((item) => item?.serialNumber)?.join(", ")
}

export const getSelectedRecordsText = (data, type) => {
  return data?.length > 1 ?
    i18n.t("tasks:pendingTasks.popup.multipleSelectRecords", {
      count: data?.length,
      type
    }) :
    i18n.t("tasks:pendingTasks.popup.singleSelectRecords", {
      count: data?.length,
      type
    })
}

export const getTasksActionPayload = (type, selectedRecords, notes) => {
  if (type === APPROVE || type === REQUEUE) {
    return getApproveOrRequeuePayload(selectedRecords, notes)
  } else {
    return getRejectPayload(selectedRecords, notes)
  }
}

export const getApproveOrRequeuePayload = (data, notes) => {
  return {
    variables: {
      notes: notes?.trim(),
      serialNumbers: data?.map(item => item.serialNumber)
    } 
  }
}

export const getRejectPayload = (data, notes) => {
  return {
    variables: {
      rejectReason: notes?.trim(),
      serialNumbers: data?.map(item => item.serialNumber)
    } 
  }
}

export const getSuccessMessage = (data, type) => {
  const tempKey = type === REQUEUE ? "requeued" : "rejected"
  const key = type === APPROVE ? "approved" : tempKey

  return data?.length > 1
    ? i18n.t("tasks:pendingTasks.popup.successMessage", {
      count: data?.length,
      text:  key
    })
    : i18n.t("tasks:pendingTasks.popup.singleSuccessMessage", {
      count: data?.length,
      text: key
    })
}

export const getErrorMessage = (data, type) => {
  return data?.length > 1
    ? i18n.t("tasks:pendingTasks.popup.errorMessage", {
      count: data?.length,
      type
    })
    : i18n.t("tasks:pendingTasks.popup.singleErrorMessage", {
      count: data?.length,
      type
    })
}

import { FormControl, IconButton, InputAdornment, OutlinedInput, Tooltip } from "@mui/material"
import PropTypes from "prop-types"
import React, { useId, useState } from "react"

import styles from "./LabelField.module.scss"

import ErrorMessage from "../../../components/ErrorMessage/ErrorMessage"
import Icon from "../../../components/Icon/Icon"
import ToggleSwitch from "../../../components/ToggleSwitch/ToggleSwitch"
import { COMPARE_VALUE, FIELD_CHAR_LENGTH, ICON_NAME, INPUT_TYPE } from "../../../utils/GlobalConstants"
import { classNames } from "../../../utils/styles/helper"

const LabelField = ({
  label,
  onChange,
  isDisabled = false,
  value = "",
  instructionText = "",
  error = "",
  type = "text",
  isRequired = false,
  inputType = INPUT_TYPE.TEXT_INPUT,
  iconTooltipLabel,
  displayIconTooltip,
  info 
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const handleClickShowPassword = () => setIsPasswordVisible((show) => !show)
  const id = useId()
  const handleChange = (event) => {
    const newValue = event.target ? event.target.value : event
    onChange?.(newValue)
  }

  const handleKeyUp = (e) => {
    if (e.key === COMPARE_VALUE.enter && e.target.value !== (label || "")) {
      handleChange(e)
    }
  }

  return (
    <tr>
      <td className={ styles.labelContainer }>
        <label className={ classNames(styles.label, isDisabled && styles.disabledLabel) } htmlFor={ id }>
          { label }
          { isRequired && <span className={ styles.required }> *</span> }
        </label>
      </td>
      <td className={ styles.labelElements }>
        { inputType === INPUT_TYPE.TOGGEL_INPUT ? (
          <ToggleSwitch
            id={ label }
            isChecked={ value }
            iconTooltipLabel={ iconTooltipLabel }
            displayIconTooltip={ displayIconTooltip }
            handleOnClick={ handleChange }
          />
        ) : (
          <FormControl
            variant="outlined"
            className={ isDisabled ? styles.disabledInputFormControl : styles.inputFormControl }
            fullWidth
          >
            <OutlinedInput
              className={ classNames("overlay", error ? "error" : "noError") }
              inputProps={ {
                "aria-disabled": isDisabled,
                id: id,
                maxLength: FIELD_CHAR_LENGTH[type] + 1
              } }
              data-testid={ label }
              onChange={ handleChange }
              value={ value.trim() }
              disabled={ isDisabled }
              type={ isPasswordVisible ? "text" : type }
              onKeyUp={ handleKeyUp }
              error={ Boolean(error) }
              autoComplete="off"
              endAdornment={
                label === "Passphrase" ? (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={ handleClickShowPassword }
                      disableRipple
                      edge="end"
                    >
                      { !isPasswordVisible ? (
                        <Icon icon={ ICON_NAME.passwordVisibilityOff } />
                      ) : (
                        <Icon icon={ ICON_NAME.passwordVisibility } />
                      ) }
                    </IconButton>
                  </InputAdornment>
                ) : null
              }
            />
            { instructionText && <span className={ styles.instructionText }>{ instructionText }</span> }
            { error && 
              <ErrorMessage icon={ ICON_NAME.alert } error={ error } errorMessageStyle={ styles.errorMessage } /> }
          </FormControl>
        ) }
      </td>
      { info && (
        <Tooltip arrow placement="bottom" title={ info }>
          <IconButton>
            <Icon icon={ ICON_NAME.info } iconStyles ={ styles.infoStyle }/>
          </IconButton>
        </Tooltip>
      ) }
    </tr>
  )
}

LabelField.propTypes = {
  displayIconTooltip: PropTypes.bool,
  error: PropTypes.string,
  iconTooltipLabel: PropTypes.string,
  info: PropTypes.string,
  inputType: PropTypes.string,
  instructionText: PropTypes.string,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  type: PropTypes.string,
  value: PropTypes.any
}

export default LabelField

import { useEffect, useState } from "react"
import { useQueryClient } from "react-query"

import { DPSGetKeys, DPSGetSVC } from "../../services/reactQueries/device-provisioningsvc"
import { StorageKeys, getSession } from "../SessionHelper/session"

const usePendingTasksCount = () => {
  const queryClient = useQueryClient()
  const [tasksCount, setTasksCount] = useState()
  const isLoggedIn = getSession(StorageKeys.USER_SESSION)
  
  useEffect(() => {
    const fetchTasksCount = async () => {
      try {
        const data = await queryClient.fetchQuery({
          queryFn: () => DPSGetSVC.getPendingTasksCount(),
          queryKey: [DPSGetKeys.GET_PENDING_TASKS_COUNT],
          staleTime: 2000
        })
        const tasksCount = data?.data?.pagination?.totalElements ?? null
        setTasksCount(tasksCount)
      } catch (err) {
        setTasksCount(null)
      }
    }
    if (isLoggedIn) fetchTasksCount()
  }, [queryClient, isLoggedIn])

  return tasksCount
}

export default usePendingTasksCount

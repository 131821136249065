import PropTypes from "prop-types"
import React, { createContext, useContext, useMemo, useState } from "react"

const CertificateProvisioningContext = createContext(null)

export const CertificateProvisioningProvider = ({ children }) => {
  const [selectedFilters, setSelectedFilters] = useState({})

  const setFilters = (payload) => {
    setSelectedFilters(payload)
  }

  const contextValue = useMemo(() => ({
    selectedFilters,
    setFilters
  }), [selectedFilters])

  return (
    <CertificateProvisioningContext.Provider value={ contextValue }>
      { children }
    </CertificateProvisioningContext.Provider>
  )
}

CertificateProvisioningProvider.propTypes = {
  children: PropTypes.any
}

export const useCertificateProvisioning = () => {
  return useContext(CertificateProvisioningContext)
}

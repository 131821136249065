/* eslint-disable react-hooks/exhaustive-deps */
import React, { createRef, useCallback, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"

import styles from "./DeploymentActivities.module.scss"
import {
  DEFAULT_DATE_RANGE,
  fetchAssetsForEachActivity,
  getCancelConfirmOverlayParams,
  getRetryConfirmOverlayParams,
  resetAccordionExpandedState,
  transformActivityAssetsData
} from "./DeploymentActivitiesUtils"
import ConfigActivitiesAccordionItem from "./internals/ConfigActivitiesAccordionItem"
import HeadSection from "./internals/HeadSection"

import ConfirmDeployConfig from "../../../../components/ConfirmDeployConfig/ConfirmDeployConfig"
import Error from "../../../../components/Error/Error"
import InlineLoading from "../../../../components/InlineLoading/InlineLoading"
import Loading from "../../../../components/Loading/Loading"
import SearchErrorPage from "../../../../components/SearchErrorPage/SearchErrorPage"
import { fileSVC, fileSVCKeys } from "../../../../services/reactQueries/filesvc"
import { TABLE_ACTION } from "../../../../utils/Constants/AssetsConfig"
import { DeploymentActivityAuthElement } from "../../../../utils/Constants/Auth/configuration"
import { SEARCH_BY_TYPES } from "../../../../utils/Constants/Search"
import { useLazyQuery } from "../../../../utils/CustomHooks/reactQuery"
import useAccess from "../../../../utils/CustomHooks/useAccess"
import { GLOBAL_THROTTLE_TIME, SEARCH_DEFAULT_MIN_CHARACTERS } from "../../../../utils/GlobalConstants"
import { throttle } from "../../../../utils/helper"
import { StorageKeys, getSession } from "../../../../utils/SessionHelper/session"

const DeploymentActivities = () => {
  const { t } = useTranslation(["configuration"])
  const queryClient = useQueryClient()
  const siteUcmId = getSession(StorageKeys.SITE_UCM_ID)
  const [deploymentActivities, setDeploymentActivities] = useState([])
  const [expandedJobId, setExpandedJobId] = useState("")
  const [isConfirmPopUp, setIsConfirmPopUp] = useState({})
  const [selectedAssets, setSelectedAssets] = useState([])
  const [currPage, setCurrPage] = useState(1)
  const allSelectedFleets = useRef([])
  const paginationDataRef = useRef({})
  const cardRef = useRef()
  const paginationLoaderRef = useRef()
  const searchBarRef = useRef(null)
  const [searchBy, setSearchBy] = useState(null)
  const [searchInput, setSearchInput] = useState(null)
  const [searchClose, setSearchClose] = useState(false)
  const [searchError, setSearchError] = useState(false)

  const getRefs = (ref) => deploymentActivities?.map((_, i) => ref?.current?.[i] ?? createRef())

  const activityCardRef = useRef([])
  activityCardRef.current = getRefs(activityCardRef)
  const lastSelectedJobIdRef = useRef(null)
  const assetSummaryRef = useRef([])
  assetSummaryRef.current = getRefs(assetSummaryRef)

  const activityQueryKey = [fileSVCKeys.GET_DEPLOY_ACTIVITIES, siteUcmId]
  const assetQueryKey = [fileSVCKeys.GET_DEPLOY_ACTIVITY_ASSETS, expandedJobId]

  const hasAnyActionAccess = useAccess([
    DeploymentActivityAuthElement.CentralCancelDeployment,
    DeploymentActivityAuthElement.CentralRetryDeployment
  ])

  const [configDateRange, setConfigDateRange] = useState({
    endDate: DEFAULT_DATE_RANGE.endDate,
    maxDate: DEFAULT_DATE_RANGE.maxDate,
    startDate: DEFAULT_DATE_RANGE.startDate
  })

  const onDataLoadCompleted = (data) => {
    paginationDataRef.current = data?.data?.pagination
    let records = data?.data?.deploymentActivities
    if (searchInput && searchBy === SEARCH_BY_TYPES.ASSET) {
      records = fetchAssetsForEachActivity(queryClient, records, siteUcmId, searchInput, hasAnyActionAccess)
    }
    const deploymentActivitiesData = [...deploymentActivities]
    const list = currPage === 1 ? records : [...deploymentActivitiesData, ...records]
    setDeploymentActivities(list)
    setExpandedJobId("")
  }

  const [getDeploymentActivities, { isFetching, isError }] = useLazyQuery(
    activityQueryKey,
    () => fileSVC.getDeploymentActivities(siteUcmId, configDateRange, currPage, searchBy, searchInput),
    {
      onSuccess: onDataLoadCompleted
    }
  )

  useEffect(() => {
    getDeploymentActivities()
  }, [configDateRange, currPage])

  useEffect(() => {
    if (searchInput?.length >= SEARCH_DEFAULT_MIN_CHARACTERS || searchClose) {
      getDeploymentActivities()
      resetSelectedAssetsData()
    }
  }, [searchInput, searchBy, searchClose])

  const handleSetConfigDateRange = (data) => {
    setCurrPage(1)
    setConfigDateRange(data)
    resetSelectedAssetsData()
    resetSearch()
  }

  const onAssetsDataLoadCompleted = (data) => {
    const transformedActivityData = transformActivityAssetsData(
      data,
      deploymentActivities,
      expandedJobId,
      hasAnyActionAccess
    )
    setDeploymentActivities(transformedActivityData)
  }

  const onAssetsDataError = () => {
    const deploymentActivitiesData = [...deploymentActivities]
    const idx = deploymentActivitiesData?.findIndex(item => item.jobId === expandedJobId)
    if (idx > -1) deploymentActivitiesData[idx].isErrorLoadingAssets = true
    setDeploymentActivities(deploymentActivitiesData)
  }

  const [getDeploymentActivityAssets, { isFetching: isFetchingAssets }] = useLazyQuery(
    assetQueryKey,
    () => fileSVC.getDeploymentActivityAssets(
      siteUcmId,
      expandedJobId,
      searchBy === SEARCH_BY_TYPES.ASSET ? searchInput : null
    ),
    {
      onError: onAssetsDataError,
      onSuccess: onAssetsDataLoadCompleted
    }
  )

  useEffect(() => {
    if (expandedJobId) getDeploymentActivityAssets()
  }, [expandedJobId])

  const reAdjustAccordionScroll = (i) => {
    activityCardRef?.current?.[i]?.current?.scrollIntoView({
      behavior: "instant"
    })
  }

  const onToggleAccordion = (expanded, id, i) => {
    if (searchInput && searchBy === SEARCH_BY_TYPES.ASSET) {
      if (lastSelectedJobIdRef.current === id) {
        resetSelectedAssetsData()
      }
    } else {
      resetSelectedAssetsData()
    }
    resetCollapsedAccordionData(id)
    if (expanded) {
      reAdjustAccordionScroll(i)
      setDeploymentActivities(resetAccordionExpandedState(deploymentActivities, id))
      setExpandedJobId(id)
    } else {
      setExpandedJobId("")
    }
  }

  const resetSelectedAssetsData = () => {
    setSelectedAssets([])
    allSelectedFleets.current = []
    lastSelectedJobIdRef.current = null
  }

  const resetCollapsedAccordionData = (id) => {
    const deploymentActivitiesData = [...deploymentActivities]
    const idx = deploymentActivitiesData?.findIndex((item) => item?.jobId === id)
    if (idx > -1) {
      deploymentActivitiesData[idx].fleets = null
      setDeploymentActivities(deploymentActivitiesData)
    }
  }

  const handleRowClickActions = (actionType, payload, isHeaderAction) => {
    switch (actionType) {
    case TABLE_ACTION.RETRY_CENTRAL_DEPLOYMENT:
      setIsConfirmPopUp(
        getRetryConfirmOverlayParams(actionType, payload, isHeaderAction)
      )
      break
    case TABLE_ACTION.CANCEL_CENTRAL_DEPLOY:
      setIsConfirmPopUp(
        getCancelConfirmOverlayParams(actionType, payload, isHeaderAction)
      )
      break
      /* istanbul ignore next */
    default:
      return null
    }
  }

  const throttleActions = useCallback(throttle(handleRowClickActions, GLOBAL_THROTTLE_TIME), [])

  const getExtractedJobId = (id) => id?.substr(0, id?.lastIndexOf("$index"))

  const resetAccordionForAssetSearch = (id) => {
    if (searchInput && searchBy === SEARCH_BY_TYPES.ASSET) {
      const extractedJobId = getExtractedJobId(id)
      if (lastSelectedJobIdRef.current && lastSelectedJobIdRef.current !== extractedJobId) {
        allSelectedFleets.current = []
        const idx = deploymentActivities.findIndex(item => item.jobId === lastSelectedJobIdRef?.current)
        if (idx > -1) assetSummaryRef?.current?.[idx]?.current?.resetCheckboxSelection()
      }
      lastSelectedJobIdRef.current = extractedJobId
    }
  }

  const handleSelectedAssets = useCallback((id, assets) => {
    resetAccordionForAssetSearch(id)
    const idx = allSelectedFleets.current.findIndex((x) => x.id === id)
    if (idx > -1) {
      allSelectedFleets.current[idx].assets = assets
    } else {
      const fleetData = { assets, id }
      allSelectedFleets.current.push(fleetData)
    }
    let allSelectedAssets = []
    for (const fleet of allSelectedFleets.current) {
      if (fleet?.assets) allSelectedAssets = [...allSelectedAssets, ...fleet.assets]
    }
    setSelectedAssets(allSelectedAssets)
  }, [deploymentActivities, searchInput])

  const handleOnSubmit = (_data) => {
    if (_data?.isSuccess) resetSelectedAssetsData()
    setIsConfirmPopUp(_data)
  }

  const handleSearch = (value, closeAction, hasError, searchBy) => {
    setSearchClose(closeAction)
    setCurrPage(1)
    setSearchBy(searchBy ?? SEARCH_BY_TYPES.ASSET)
    setSearchInput(value)
    if (
      (closeAction && !hasError) ||
        (!closeAction && value.length >= SEARCH_DEFAULT_MIN_CHARACTERS)
    ) {
      setSearchError(false)
    } else {
      setSearchError(true)
    }
  }

  const resetSearch = () => {
    setSearchInput(null)
    setSearchError(false)
    setSearchClose(true)
    searchBarRef.current?.reset()
  }

  const onScroll = () => {
    if (cardRef.current && !isError && !isFetching && deploymentActivities?.length > 0) {
      const { scrollTop, scrollHeight, clientHeight } = cardRef.current
      const diffrence = scrollHeight - (Math.floor(scrollTop) + clientHeight)
      if (diffrence <= 1) {
        const hasNext = paginationDataRef?.current?.hasNext
        if (hasNext && !isFetching) setCurrPage(currPage + 1)
      }
    }
  }

  const calculateScrollHeight = () => {
    if (cardRef?.current && cardRef?.current?.scrollHeight === cardRef?.current?.clientHeight) {
      const hasNext = paginationDataRef?.current?.hasNext
      if (hasNext && !isFetching) {
        setCurrPage(currPage + 1)
      }
    }
  }

  useEffect(() => {
    if (isFetching && currPage > 1) {
      paginationLoaderRef?.current?.scrollIntoView({
        behavior: "smooth"
      })
    } else {
      calculateScrollHeight()
    }
  }, [isFetching])

  useEffect(() => {
    window.addEventListener("resize", () => calculateScrollHeight())
  }, [])

  return (
    <>
      <HeadSection
        t={ t }
        configDateRange={ configDateRange }
        setConfigDateRange={ (data) => handleSetConfigDateRange(data) }
        selectedAssets={ selectedAssets }
        handleClick={ (action) => handleRowClickActions(action, selectedAssets, true) }
        isConfirmPopUp={ isConfirmPopUp }
        handleSearch={ handleSearch }
        isLoading={ isFetching && currPage === 1 }
        searchInput={ searchInput }
        searchResultCount={ paginationDataRef?.current?.totalElements }
        searchBarRef={ searchBarRef }
        isSearchDisabled={ !searchInput && searchInput == null && deploymentActivities?.length === 0 }
      />
      <div className={ styles.configContainer } 
        onScroll={ onScroll } 
        ref={ cardRef } 
        data-testid={ "card-scrollContainer" }
      >
        { isFetching && currPage <= 1 && <Loading /> }
        { isError && !isFetching && (
          <div>
            <Error error={ { message: t("centralConfigurations.deploymentActivities.getErrorMessage") } } />
          </div>
        ) }
        { !isFetching && searchError && !isError && <SearchErrorPage /> }
        { !isFetching && !isError && !searchError && currPage <= 1 && deploymentActivities?.length === 0 && (
          <div className={ styles.noConfig }>
            { 
              searchInput ?
                t("centralConfigurations.deploymentActivities.noSearchData") :
                t("centralConfigurations.deploymentActivities.noData")
            }
          </div>
        ) }
        { ((isFetching && currPage <= 1) || isError || searchError ? [] : deploymentActivities)?.map((item, i) => (
          <div key={ item.jobId } ref={ activityCardRef?.current?.[i] } className={ styles.activityCardContainer }>
            <ConfigActivitiesAccordionItem
              t={ t }
              activityDetails={ item }
              onToggleAccordion={ (expanded) => onToggleAccordion(expanded, item.jobId, i) }
              isLoadingAssets={ item.jobId === expandedJobId ? isFetchingAssets : false }
              throttleActions={ throttleActions }
              handleSelectedAssets={ handleSelectedAssets }
              assetQueryKey={ [fileSVCKeys.GET_DEPLOY_ACTIVITY_ASSETS, item.jobId] }
              expandAllAccordion={ searchInput && searchBy === SEARCH_BY_TYPES.ASSET }
              ref={ assetSummaryRef.current?.[i] }
            />
          </div>
        )) }
        { isFetching && currPage > 1 && <InlineLoading ref={ paginationLoaderRef } /> }
      </div>
      <ConfirmDeployConfig
        { ...isConfirmPopUp }
        onCancel={ (value) => setIsConfirmPopUp({ value }) }
        onSubmit={ handleOnSubmit }
        popupStyles={ styles.confirmDeployPopup }
        queryKey={ isConfirmPopUp?.activityQueryKey }
        siteUcmId={ siteUcmId }
      />
    </>
  )
}

export default DeploymentActivities

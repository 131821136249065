/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"

import styles from "./QRScanner.module.scss"

import { useAuthContext } from "../../contexts/Auth/auth"
import { TYPES, useQRScanner } from "../../contexts/qrScanner/QRScannerContext"
import { useToaster } from "../../contexts/ToastContext"
import { resourceGETSvc, resourceSVCKeys } from "../../services/reactQueries/resourcesvc"
import { useLazyQuery } from "../../utils/CustomHooks/reactQuery"
import { CLOSE } from "../../utils/GlobalConstants"
import ConfirmationPopup from "../ConfirmationPopup/ConfirmationPopup"
import Loading from "../Loading/Loading"

const QRScanner = () => {
  const { t } = useTranslation(["qrcodeScanner"])
  const { displayMediumErrorToast } = useToaster()
  const {
    state: { isOpen },
    dispatch
  } = useQRScanner()

  const { getTokenData } = useAuthContext()
  const userData = useMemo(() => getTokenData(), [isOpen])

  const handleClose = () => {
    dispatch({ payload: { isOpen: false }, type: TYPES.closePopup })
  }

  const displayError = () => {
    displayMediumErrorToast(t("error"))
    handleClose()
  }

  const [getQR, { data, isLoading }] = useLazyQuery(
    [resourceSVCKeys.GET_QRCODE],
    () => resourceGETSvc.getQRCode(userData?.sub),
    { onError: displayError }
  )

  const imageURL = !isLoading && data?.data?.qrImage

  useEffect(() => {
    if (isOpen) {
      getQR()
    }
  }, [getQR, isOpen])

  return (
    <div className={ styles.popupContainer }>
      <ConfirmationPopup
        isOpen={ isOpen }
        popupStyles={ styles.popupStyles }
        heading={ t("popupTitle") }
        onSubmit={ handleClose }
        confirm={ CLOSE }
      >
        <div className={ styles.qrContainer }>
          { isLoading ? (
            <Loading />
          ) : (
            <img src={ `data:image/jpeg;base64,${imageURL}` } alt="qrCode" className={ styles.qrImage } />
          ) }
        </div>
      </ConfirmationPopup>
    </div>
  )
}

export default QRScanner

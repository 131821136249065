import { Button } from "@mui/material"
import React, { useEffect, useReducer, useState } from "react"
import { useTranslation } from "react-i18next"

import styles from "./GatewayConfiguration.module.scss"
import { initialState, textFields, transformData, updateReducer, validatePassphrase } from "./gatewayConfigUtils"

import Icon from "../../../../components/Icon/Icon"
import { useToaster } from "../../../../contexts/ToastContext"
import { gatewayconfigsvc } from "../../../../services/reactQueries/gatewayconfigsvc"
import { GATEWAY_ERROR_CODE } from "../../../../utils/Constants/ErrorCodes"
import { useMutationWithHandlers } from "../../../../utils/CustomHooks/reactQuery"
import { ICON_NAME } from "../../../../utils/GlobalConstants"
import LabelField from "../../internal/LabelField"

const GatewayConfiguration = () => {
  const { t } = useTranslation(["tasks"])
  const [state, dispatch] = useReducer(updateReducer, initialState)
  const [isResetEnabled, setIsResetEnabled] = useState(false)
  const [passphraseError, setPassphraseError] = useState("")
  const { displayMediumErrorToast } = useToaster()

  const onDownloadTriggeredSuccessfull = (data) => {
    const url = data?.data?.preSignedUrl
    if (url) {
      window.open(url, "_blank")
    } else {
      displayMediumErrorToast(t("tabs.messages.errorMessage"))
    }
  }
  const onDownloadTriggeredFailed = (error) => {
    if (error?.data?.error?.code === GATEWAY_ERROR_CODE.ISO_INVALID_SERIAL_NUMBER) {
      displayMediumErrorToast(t("tabs.messages.invalidSerialNumber"))
    } else {
      displayMediumErrorToast(t("tabs.messages.errorMessage"))
    }
  }

  const { requestMutation: downloadISO, isLoading } = useMutationWithHandlers({
    onCompletedCallback: onDownloadTriggeredSuccessfull,
    onErrorCallback: onDownloadTriggeredFailed,
    queryFn: gatewayconfigsvc.getIOSImage,
    refetchQueries: ["getISO", state.serialNumber]
  })

  useEffect(() => {
    setIsResetEnabled(JSON.stringify(state) !== JSON.stringify(initialState))
  }, [state])

  const handleChange = (field) => (value) => {
    if (field === "passphrase") {
      setPassphraseError("")
    }
    dispatch({ field, type: "UPDATE_FIELD", value })
  }

  const handleResetForm = () => {
    dispatch({ type: "RESET_FORM" })
  }

  const handleSubmit = () => {
    if (!validatePassphrase(state.passphrase)) {
      setPassphraseError(t("tabs.messages.passphraseError"))
    } else {
      downloadISO({
        payload: {
          variables: transformData(state)
        }
      })
    }
  }

  const isFormValid = textFields.every(({ key, required }) => !required || state[key].trim() !== "")
  return (
    <div className={ styles.GatewayConfigurationContainer }>
      <table className={ styles.gatewayTable }>
        <tbody>
          { textFields.map(({ label, key, required, type, info }) => (
            <LabelField
              key={ key }
              label={ label }
              isRequired={ required }
              value={ state[key] }
              onChange={ handleChange(key) }
              type={ type }
              inputType={ type }
              info={ info }
              isDisabled={
                (!state.proxy && (key === "host" || key === "port" || key === "authentication")) ||
                (!state.authentication && (key === "username" || key === "password"))
              }
              error={ key === "passphrase" ? passphraseError : "" }
            />
          )) }
        </tbody>
      </table>
      <div className={ styles.buttonGroup }>
        <Button onClick={ handleResetForm } variant="outlined" disabled={ !isResetEnabled } data-testid="resetButton">
          { t("tabs.gatewayConfigurationButtons.reset") }
        </Button>
        <span className={ styles.buttonContainer }>
          <Button
            variant="contained"
            color="primary"
            size="large"
            data-testid="confirmButton"
            disabled={ !isFormValid }
            onClick={ handleSubmit }
          >
            { isLoading ? (
              <Icon icon={ ICON_NAME.btnLoader } iconStyles={ styles.loadingIcon } />
            ) : (
              t("tabs.gatewayConfigurationButtons.generate")
            ) }
          </Button>
        </span>
      </div>
    </div>
  )
}

export default GatewayConfiguration

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "@mui/material"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import MultipleSelectDropdown from "../../../../../components/Dropdown/MultipleSelectDropdown"
import SingleSelectDropdown from "../../../../../components/Dropdown/SingleSelectDropdown"
import Icon from "../../../../../components/Icon/Icon"
import { useCertificateProvisioning } from "../../../../../contexts/certificateProvisioning/CertificateProvisioning"
import { DPSGetKeys, DPSGetSVC } from "../../../../../services/reactQueries/device-provisioningsvc"
import { useLazyQuery } from "../../../../../utils/CustomHooks/reactQuery"
import useIsCustomerRoute from "../../../../../utils/CustomHooks/useIsCustomerRoute"
import useIsSiteRoute from "../../../../../utils/CustomHooks/useIsSiteRoute"
import { ICON_NAME } from "../../../../../utils/GlobalConstants"
import { StorageKeys, getSession } from "../../../../../utils/SessionHelper/session"
import styles from "../CertificateProvisioning.module.scss"
import {
  extractCustomerDropdownValue,
  extractModalityModelDropdownValue,
  extractSiteDropdownValue,
  transformDropdown
} from "../CertificateProvisioningUtils"

const FilterSection = () => {
  const { t } = useTranslation(["tasks"])
  const { setFilters } = useCertificateProvisioning()
  const [customerValue, setCustomerValue] = useState("")
  const [siteValue, setSiteValue] = useState([])
  const [modalityValue, setModalityValue] = useState([])
  const [modelValue, setModelValue] = useState([])

  const customerOptions = useRef([])
  const siteOptions = useRef([])
  const modalityOptions = useRef([])
  const modelOptions = useRef([])
  
  const isCustomerRoute = useIsCustomerRoute()
  const isSiteRoute = useIsSiteRoute()

  const customerUcmId = getSession(StorageKeys.CUSTOMER_UCM_ID)
  const siteUcmId = getSession(StorageKeys.SITE_UCM_ID)

  const [filterValues, setFilterValues] = useState({
    customerId: isCustomerRoute ? customerUcmId : "",
    modalities: [],
    models: [],
    siteIds: isSiteRoute ? [siteUcmId] : []
  })

  const [getCustomers] = useLazyQuery([DPSGetKeys.GET_CUSTOMERS_DROPDOWN],
    () => DPSGetSVC.getCustomers(), {
      onSuccess: (data) => {
        customerOptions.current = transformDropdown(data?.data?.customers, "customerId", "customerName") ?? []
      }
    }
  )
  const [getSites] = useLazyQuery([DPSGetKeys.GET_SITES_DROPDOWN],
    () => DPSGetSVC.getSites(filterValues), {
      onSuccess: (data) => {
        siteOptions.current = transformDropdown(data?.data?.sites, "siteId", "siteName") ?? []
      }
    }
  )
  const [getModalities] = useLazyQuery([DPSGetKeys.GET_MODALITY_DROPDOWN],
    () => DPSGetSVC.getModalities(filterValues), {
      onSuccess: (data) => {
        modalityOptions.current = transformDropdown(data?.data?.modalities) ?? []
      }
    }
  )
  const [getModels] = useLazyQuery([DPSGetKeys.GET_MODELS_DROPDOWN],
    () => DPSGetSVC.getModels(filterValues), {
      onSuccess: (data) => {
        modelOptions.current = transformDropdown(data?.data?.models) ?? []
      }
    }
  )

  useEffect(() => {
    if(!isCustomerRoute) getCustomers()
    if(!isSiteRoute) getSites()
    getModalities()
    getModels()
    onFilterSubmit()
  }, [])

  useEffect(() => {
    if (!isCustomerRoute) {
      siteOptions.current = []
      modalityOptions.current = []
      modelOptions.current = []
      setSiteValue([])
      setModalityValue([])
      setModelValue([])
      getSites()
      getModalities()
      getModels()
    }
  }, [customerValue])

  useEffect(() => {
    if (!isSiteRoute) {
      modalityOptions.current = []
      modelOptions.current = []
      setModalityValue([])
      setModelValue([])
      getModalities()
      getModels()
    }
  }, [siteValue])

  useEffect(() => {
    modelOptions.current = []
    setModelValue([])
    getModels()
  }, [modalityValue])
  
  const handleCustomerSelect = (value) => {
    setFilterValues(preState => {
      return {
        ...preState,
        customerId: extractCustomerDropdownValue(value, customerOptions?.current),
        modalities: [],
        models: [],
        siteIds: []
      }
    })
    setCustomerValue(value)
  }

  const handleSitesSelect = (value) => {
    const formatedValue = isCustomerRoute && !isSiteRoute ? [value] : value
    setFilterValues(preState => {
      return {
        ...preState,
        modalities: [],
        models: [],
        siteIds: extractSiteDropdownValue(formatedValue, siteOptions?.current)
      }
    })
    setSiteValue(value)
  }

  const handleModalitiesSelect = (value) => {
    setFilterValues(preState => {
      return {
        ...preState,
        modalities: extractModalityModelDropdownValue(value, modalityOptions?.current),
        models: []
      }
    })
    setModalityValue(value)
  }

  const handleModelsSelect = (value) => {
    setFilterValues(preState => {
      return {
        ...preState,
        models: extractModalityModelDropdownValue(value, modelOptions?.current)
      }
    })
    setModelValue(value)
  }

  const onFilterSubmit = () => {
    setFilters(filterValues)
  }

  const SiteDropdown = (isCustomerRoute && !isSiteRoute) ? SingleSelectDropdown : MultipleSelectDropdown

  return (
    <div className={ styles.dropDownContainer }>
      <div className={ styles.dropDown }>
        { !isCustomerRoute && (
          <SingleSelectDropdown
            data-testid="customer"
            options={ customerOptions?.current }
            value={ customerValue }
            width={ 180 }
            placeholder={ t("headSection.dropDown.customer") }
            onChange={ handleCustomerSelect }
          />
        ) }
        { !isSiteRoute && (
          <SiteDropdown
            data-testid="site"
            options={ siteOptions?.current }
            value={ siteValue }
            width={ 180 }
            displayLabelText={ false }
            placeholder={ t("headSection.dropDown.site") }
            onChange={ handleSitesSelect }
          />
        ) }
        <MultipleSelectDropdown
          data-testid="modality"
          options={ modalityOptions?.current }
          value={ modalityValue }
          width={ 180 }
          displayLabelText={ false }
          placeholder={ t("headSection.dropDown.modality") }
          onChange={ handleModalitiesSelect }
        />
        <MultipleSelectDropdown
          data-testid="model"
          options={ modelOptions?.current }
          value={ modelValue }
          width={ 180 }
          displayLabelText={ false }
          placeholder={ t("headSection.dropDown.model") }
          onChange={ handleModelsSelect }
        />
        <Button
          variant="text"
          color="secondary"
          startIcon={ <Icon icon={ ICON_NAME.checkmarkBlue } /> }
          className={ styles.getButton }
          onClick={ onFilterSubmit }
        >
          { t("headSection.buttons.apply") }
        </Button>
      </div>
    </div>
  )
}

export default FilterSection

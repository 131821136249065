export const transformDropdown = (data, idKey, valueKey) => {
  const options = []
  data?.map((item, index) => {
    let id = index + 1
    let label = item
    let key = null
    if (typeof item === "object") {
      key = item[idKey]
      label = item[valueKey]
    } 
    options?.push({ id, key, label })
  })
  return options
}

export const extractCustomerDropdownValue = (value, options) => {
  const idx = options.findIndex(item => item.id === value)
  if (idx > -1) {
    return options[idx].key
  }
  return ""
}

export const extractSiteDropdownValue = (data, options) => {
  const ids = []
  options.forEach(item => {
    if (data.includes(item.id)) ids.push(item.key)
  })
  return ids
}

export const extractModalityModelDropdownValue = (data, options) => {
  const ids = []
  options.forEach(item => {
    if (data.includes(item.id)) ids.push(item.label)
  })
  return ids
}

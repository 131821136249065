import { useParams } from "react-router-dom"

import { ROUTE_CONFIG } from "../GlobalConstants"

const useIsSiteRoute = () => {
  const queryParams = useParams()
  return !!queryParams[ROUTE_CONFIG.SITE.param]
}

export default useIsSiteRoute

/* eslint-disable react-hooks/exhaustive-deps */
import { Tab, Tabs } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import CertificateProvisioning from "./Tabs/CertificateProvisioning/CertificateProvisioning"
import GatewayConfiguration from "./Tabs/GatewayConfiguration/GatewayConfiguration"
import GatewayDeployment from "./Tabs/GatewayDeployment/GatewayDeployment"
import styles from "./TasksView.module.scss"

import { BREADCRUMB_ROUTE } from "../../components/Breadcrumb/BreadcrumbUtils"
import TabPanel from "../../components/TabPanel/TabPanel"
import { useBreadcrumbContext } from "../../contexts/breadcrumb/breadcrumb"
import { CertificateProvisioningProvider } from "../../contexts/certificateProvisioning/CertificateProvisioning"
import { CommonAuthElement } from "../../utils/Constants/Auth/common"
import { TasksAuthElement } from "../../utils/Constants/Auth/tasks"
import useAccess from "../../utils/CustomHooks/useAccess"
import { StorageKeys, clearSession, getSession } from "../../utils/SessionHelper/session"
import { classNames } from "../../utils/styles/helper"

const TasksView = () => {
  const { t } = useTranslation(["tasks"])
  const [value, setValue] = useState(0)
  const { updateBreadcrumbData, setIsCustomerDisabled } = useBreadcrumbContext()

  const hasCertificateProAccess = useAccess([
    TasksAuthElement.ReadPendingTasks,
    TasksAuthElement.ReadRejectedTasks,
    TasksAuthElement.ReadCompletedTasks
  ])
  const hasAnyAccess = useAccess(CommonAuthElement.Any)

  useEffect(() => {
    const custName = getSession(StorageKeys.SET_CUST_NAME)
    if (custName) {
      handleUpdateCustomerName(custName)
      clearSession(StorageKeys.SET_CUST_NAME)
    }
  }, [])

  const handleUpdateCustomerName = (name) => {
    setIsCustomerDisabled(false)
    updateBreadcrumbData({
      key: BREADCRUMB_ROUTE.CUSTOMER,
      name
    })
  }

  const allTabs = [
    {
      component: (
        <CertificateProvisioningProvider>
          <CertificateProvisioning />
        </CertificateProvisioningProvider>
      ),
      hasAccess: hasCertificateProAccess,
      id: 1,
      label: t("tabs.certificateProvisioning")
    },
    {
      component: (
        <GatewayConfiguration />
      ),
      hasAccess: hasAnyAccess,
      id: 2,
      label: t("tabs.gatewayConfiguration")
    },
    {
      component: (
        <GatewayDeployment />
      ),
      hasAccess: hasAnyAccess,
      id: 3,
      label: t("tabs.gatewayDeployment")
    }
  ]

  const tabs = allTabs.filter((tab) => tab.hasAccess)

  return (
    <>
      <Tabs
        className={ classNames(styles.tabsContainer, "reportTabs") }
        value={ value }
        onChange={ (_event, newValue) => setValue(newValue) }
      >
        { tabs.map((tab, index) => (
          <Tab key={ tab.id } label={ tab.label } { ...a11yProps(index) } />
        )) }
      </Tabs>
      { tabs.map((tab, index) => (
        <TabPanel
          key={ tab.id }
          className={ styles.tabPanel }
          value={ value }
          index={ index }
        >
          { tab.component }
        </TabPanel>
      )) }
    </>
  )
}

export default TasksView

const a11yProps = (index) => {
  return {
    "aria-controls": `tasks-tabpanel-${index}`,
    id: `tasks-tab-${index}`
  }
}

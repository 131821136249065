import { Tooltip } from "@mui/material"
import PropTypes from "prop-types"
import React, { useEffect, useId, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import styles from "./TasksActionPopUp.module.scss"
import {
  getErrorMessage,
  getSelectedRecordsText,
  getSerialNumbersList,
  getSuccessMessage,
  getTasksActionPayload
} from "./TasksActionPopUpUtils"

import { DPSGetSVC } from "../../services/reactQueries/device-provisioningsvc"
import { useMutationWithHandlers } from "../../utils/CustomHooks/reactQuery"
import {
  APPROVE,
  CANCEL,
  ICON_NAME,
  REJECT,
  REQUEUE,
  TEXT_FIELD_MAX_CHAR,
  specialCharactersNotAllowedForSearch
} from "../../utils/GlobalConstants"
import { removeEmoji } from "../../utils/InputValidator/validator"
import { classNames } from "../../utils/styles/helper"
import ConfirmationPopup from "../ConfirmationPopup/ConfirmationPopup"
import Icon from "../Icon/Icon"

const TasksActionPopUp = ({
  heading,
  type,
  handleSubmit,
  handleCancel,
  isOpen,
  queryKey,
  selectedRecords
}) => {
  const id = useId()
  const { t } = useTranslation("tasks")
  const [showInfoTooltip, setShowInfoTooltip] = useState(false)
  const [notes, setNotes] = useState("")
  const textElementRef = useRef(null)

  const addNoteText = type === APPROVE ? 
    t("pendingTasks.popup.addNote") :
    t("pendingTasks.popup.addNoteMandatory")

  const serialNumbersList = getSerialNumbersList(selectedRecords)
  const selectedMessageText = getSelectedRecordsText(selectedRecords, type)

  const compareSize = () => {
    const compare =
      textElementRef?.current?.scrollHeight >
      textElementRef?.current?.offsetHeight
    setShowInfoTooltip(compare)
  }

  useEffect(() => {
    setNotes("")
    compareSize()
    window.addEventListener("resize", compareSize)
  }, [isOpen])


  const getSerialNumberCodeContainer = () => {
    return (
      <div aria-labelledby={ id } ref={ textElementRef }>
        { serialNumbersList }
      </div>
    )
  }

  const { requestMutation, isLoading } = useMutationWithHandlers({
    onCompletedCallback: () => handleSubmit(),
    onErrorCallback: () => handleSubmit(),
    queryFn: DPSGetSVC.tasksActionCall,
    refetchQueries: [
      { id: queryKey[1], key: queryKey[0] }
    ]
  })

  const onSubmit = () => {
    requestMutation({
      errorMessage: getErrorMessage(selectedRecords, type),
      payload: { ...getTasksActionPayload(type, selectedRecords, notes), type },
      successMessage: getSuccessMessage(selectedRecords, type)
    })
  }

  const handleOnChangeTextMsg = (event) => {
    const value = event.target.value.replace(specialCharactersNotAllowedForSearch, "")
    let msg = removeEmoji(value)
    setNotes(msg)
  }

  const isSubmitDisabled = (type === REQUEUE || type === REJECT) ? !(notes?.trim()) : null

  return (
    <ConfirmationPopup
      popupStyles={ classNames(
        styles.popupStyles,
        type === APPROVE ? styles.ackOverlay : styles.unAckOverlay
      ) }
      heading={ heading }
      isOpen={ isOpen }
      confirm={ type }
      cancel={ CANCEL }
      onSubmit={ onSubmit }
      onCancel={ handleCancel }
      isLoading={ isLoading }
      isConfirmEnable={ isSubmitDisabled }
    >
      <div className={ styles.messageContainer }>{ selectedMessageText }</div>
      <div className={ styles.serialNumberWrapper }>
        <label id={ id } className={ styles.serialNumberLabel }>
          { t("pendingTasks.popup.serialNumber") }
        </label>
        <span className={ styles.serialNumber }>
          <Tooltip
            title={ <div className={ styles.tooltipTitle }>{ serialNumbersList }</div> }
            interactive="true"
            disableHoverListener={ !showInfoTooltip }
            arrow
            placement="bottom-end"
            PopperProps={ { className: "ApproveRejectSerialsCodeTooltip" } }
          >
            { getSerialNumberCodeContainer() }
          </Tooltip>
        </span>
      </div>
      
      <div className={ styles.textFieldContainer }>
        <div className={ styles.addNote }>
          { addNoteText }
          { (type === REQUEUE || type === REJECT) && (
            <Icon icon={ ICON_NAME.required } iconStyles={ styles.reqIcon } />
          ) }
        </div>
        <textarea
          className={ classNames("textArea", styles.textField) }
          maxLength={ TEXT_FIELD_MAX_CHAR }
          value={ notes }
          onChange={ handleOnChangeTextMsg }
        />
        <div className={ styles.instructionText }>
          { t("pendingTasks.popup.maxLength", {
            maxLength: TEXT_FIELD_MAX_CHAR
          }) }
        </div>
      </div>
    </ConfirmationPopup>
  )
}

TasksActionPopUp.propTypes = {
  handleCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  heading: PropTypes.string,
  isOpen: PropTypes.bool,
  queryKey: PropTypes.array,
  selectedRecords: PropTypes.array,
  type: PropTypes.string
}

export default TasksActionPopUp

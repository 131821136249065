/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "@mui/material"
import PropTypes from "prop-types"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"

import AuthGuard from "../../../../../../../components/AuthGuard/AuthGuard"
import Icon from "../../../../../../../components/Icon/Icon"
import SearchBar from "../../../../../../../components/SearchBar/SearchBar"
import { TasksAuthElement } from "../../../../../../../utils/Constants/Auth/tasks"
import { SEARCH_INSTANCES } from "../../../../../../../utils/Constants/Search"
import { APPROVE, ICON_NAME, REJECT } from "../../../../../../../utils/GlobalConstants"
import styles from "../PendingTasks.module.scss"
import { getAssetSelectionText } from "../PendingTasksUtils"

const HeadSection = ({
  selectedAssets,
  setTasksActionPopUp,
  searchBarRef,
  isSearchDisabled,
  handleSearch,
  searchResultCount,
  searchError
}) => {
  const { t } = useTranslation(["tasks"])

  const enableAction = selectedAssets.length > 0

  const handleApproveClick = () => {
    setTasksActionPopUp({
      heading: t("pendingTasks.popup.approveHeading"),
      isOpen: true,
      selectedRecords: selectedAssets,
      type: APPROVE
    })
  }
  
  const handleRejectClick = () => {
    setTasksActionPopUp({
      heading: t("pendingTasks.popup.rejectHeading"),
      isOpen: true,
      selectedRecords: selectedAssets,
      type: REJECT
    })
  }

  const assetSelectionText = useMemo(
    () => getAssetSelectionText(selectedAssets, searchResultCount, searchError, t),
    [selectedAssets, searchResultCount, searchError]
  )

  return (
    <div className={ styles.headerContainer }>
      <div className={ styles.rightPanel }>
        <div>{ assetSelectionText }</div>
        <AuthGuard actionType={ TasksAuthElement.RejectPendingTasks }>
          <Button
            variant="text"
            color="secondary"
            startIcon={ <Icon icon={ enableAction ? ICON_NAME.cancelBlue : ICON_NAME.cancelBlueDisabled } /> }
            disabled={ !enableAction }
            className={ styles.rejectButton }
            onClick={ handleRejectClick }
          >
            { t("pendingTasks.headSection.buttons.reject") }
          </Button>
        </AuthGuard>
        <AuthGuard actionType={ TasksAuthElement.ApprovePendingTasks }>
          <Button
            variant="text"
            color="secondary"
            startIcon={ <Icon icon={ enableAction ? ICON_NAME.checkmarkBlue : ICON_NAME.checkmarkBlueDisabled } /> }
            disabled={ !enableAction }
            className={ styles.approveButton }
            onClick={ handleApproveClick }
          >
            { t("pendingTasks.headSection.buttons.approve") }
          </Button>
        </AuthGuard>
        <SearchBar
          type={ SEARCH_INSTANCES.pendingTasks }
          ref={ searchBarRef }
          isDisabled={ isSearchDisabled }
          onHandleSubmit={ handleSearch }
        />
      </div>
    </div>
  )
}

HeadSection.propTypes = {
  handleSearch: PropTypes.func,
  isSearchDisabled: PropTypes.bool,
  searchBarRef: PropTypes.any,
  searchError: PropTypes.bool,
  searchResultCount: PropTypes.number,
  selectedAssets: PropTypes.array,
  setTasksActionPopUp: PropTypes.func
}

export default HeadSection

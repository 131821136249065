import { IconButton, Tooltip } from "@mui/material"
import { PropTypes } from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"

import Icon from "../../../../../components/Icon/Icon"
import { ICON_NAME } from "../../../../../utils/GlobalConstants"
import styles from "../CertificateProvisioning.module.scss"

const AssetInfo = ({ row, showRejectReason = false }) => {
  const { t } = useTranslation(["tasks"])

  const tooltipTitle = () => {
    return (
      <span className={ styles.tooltipTitleContainer }>
        <span className={ styles.labelStyles }>
          <span>{ t("infoTooltip.groupName") } : </span>
          <span className={ styles.valueStyles }>{ row?.original?.groupName }</span>
        </span>
        <span className={ styles.labelStyles }>
          <span>{ t("infoTooltip.authId") } : </span>
          <span className={ styles.valueStyles }>{ row?.original?.authId }</span>
        </span>
        { showRejectReason && (
          <span className={ styles.labelStyles }>
            <span>{ t("infoTooltip.rejectionReason") } : </span>
            <span className={ styles.valueStyles }>{ row?.original?.rejectedReason }</span>
          </span>
        ) }
      </span>
    )
  }

  return (
    <div className={ styles.actionsCell }>
      <Tooltip
        arrow
        interactive="true"
        placement={ "bottom-end" }
        classes={ { tooltip: "taskInfoTooltip" } }
        title={ <span>{ tooltipTitle() }</span> }
      >
        <IconButton
          aria-label={ row?.original?.id + "-info" }
          disableTouchRipple
        >
          <Icon icon={ ICON_NAME.swInfo } iconStyles={ styles.actionIcon } />
        </IconButton>
      </Tooltip>
    </div>
  )
}

AssetInfo.propTypes = {
  row: PropTypes.any,
  showRejectReason: PropTypes.bool
}

export default AssetInfo

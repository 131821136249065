/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types"
import React, { forwardRef, useImperativeHandle, useRef } from "react"
import { useIsFetching } from "react-query"

import CompletedTasksColumnConfig from "./CompletedTasksColumnConfig"

import DataTable from "../../../../../../../components/DataTable/DataTable"
import { TABLE_INSTANCES } from "../../../../../../../utils/Constants/DataTable"

// eslint-disable-next-line react/display-name
const AssetDetails = forwardRef(({
  queryKey,
  currPage,
  tableData,
  paginationData,
  handlePaginationChange,
  customNoRowsMessage
}, ref) => {
  const tableRef = useRef(null)
  const isFetching = useIsFetching({ queryKey })
  
  useImperativeHandle(ref, () => ({
    resetTablePagination: () => {
      tableRef?.current?.resetPagination()
    }
  }))

  return (
    <DataTable
      ref={ tableRef }
      rowHoverEffect
      tableData={ isFetching && currPage === 1 ? [] : tableData }
      columnsData={ CompletedTasksColumnConfig() }
      type={ TABLE_INSTANCES.COMPLETED_TASKS }
      customNoRowsMessage={ customNoRowsMessage }
      queryKey={ queryKey }
      enableScrollPagination
      handleSortPaginationChange={ handlePaginationChange }
      paginationData={ paginationData }
    />
  )
})

AssetDetails.propTypes = {
  currPage: PropTypes.number,
  customNoRowsMessage: PropTypes.string,
  handlePaginationChange: PropTypes.func, 
  paginationData: PropTypes.object,
  queryKey: PropTypes.any,
  tableData: PropTypes.array
}

export default AssetDetails

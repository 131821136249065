import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import Backend from "i18next-http-backend"
import { initReactI18next } from "react-i18next"

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      // translation file path
      loadPath: "/assets/i18n/{{ns}}/{{lng}}.json"
    },
    // disabled in production
    debug: false,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
      formatSeparator: ","
    },
    // can have multiple namespaces, in case you want to divide a huge
    // translation into smaller pieces and load them on demand
    ns: [
      "common",
      "asset",
      "userMenu",
      "statistics",
      "fleet",
      "site",
      "subFleet",
      "fleetCardMenu",
      "toaster",
      "search",
      "login",
      "configDeployment",
      "dataTable",
      "assignSites",
      "Profile",
      "confirmDeploy",
      "assetFault",
      "reports",
      "about",
      "help",
      "qrcodeScanner",
      "logFiles",
      "tasks"
    ],

    react: {
      useSuspense: true
    }
  })

export default i18n

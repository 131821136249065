/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import AssetDetails from "./internals/AssetDetails"
import HeadSection from "./internals/HeadSection"
import styles from "./RejectedTasks.module.scss"
import { initialTasksActionState, transformAssets } from "./RejectedTasksUtils"

import Error from "../../../../../../components/Error/Error"
import Loading from "../../../../../../components/Loading/Loading"
import SearchErrorPage from "../../../../../../components/SearchErrorPage/SearchErrorPage"
import TasksActionPopUp from "../../../../../../components/TasksActionPopUp/TasksActionPopUp"
import { pkiBusiness } from "../../../../../../config/serverConfig"
import { useCertificateProvisioning } from "../../../../../../contexts/certificateProvisioning/CertificateProvisioning"
import { DPSGetKeys, DPSGetSVC } from "../../../../../../services/reactQueries/device-provisioningsvc"
import { isEmptyObject } from "../../../../../../utils/Common/common"
import { useLazyQuery } from "../../../../../../utils/CustomHooks/reactQuery"
import { SEARCH_DEFAULT_MIN_CHARACTERS } from "../../../../../../utils/GlobalConstants"

const RejectedTasks = () => {
  const { t } = useTranslation(["tasks"])
  const { selectedFilters } = useCertificateProvisioning()
  const [rejectedTasks, setRejectedTasks] = useState()
  const [selectedAssets, setSelectedAssets] = useState([])
  const [currPage, setCurrPage] = useState(1)
  const paginationDataRef = useRef({})
  const assetDetailsRef = useRef()
  const [tasksActionPopup, setTasksActionPopUp] = useState(initialTasksActionState)
  const searchBarRef = useRef(null)
  const [searchInput, setSearchInput] = useState(null)
  const [searchClose, setSearchClose] = useState(false)
  const [searchError, setSearchError] = useState(false)

  const queryKey = [DPSGetKeys.GET_REJECTED_TASKS]

  const onDataLoadCompleted = (data) => {
    paginationDataRef.current = data?.data?.pagination
    let records = transformAssets(data?.data?.provisioningRecords)
    const rejectedTasksData = rejectedTasks ? [...rejectedTasks] : []
    const list = currPage === 1 ? records : [...rejectedTasksData, ...records]
    setRejectedTasks(list)
  }

  const [getRejectedTasks, { isFetching, isError }] = useLazyQuery(
    queryKey,
    () => DPSGetSVC.getRejectedTasks(
      currPage,
      {
        variables: {
          business: pkiBusiness,
          searchString: searchInput ?? "",
          ...selectedFilters
        }
      }),
    {
      onSuccess: onDataLoadCompleted
    }
  )

  useEffect(() => {
    resetTableSelection()
    const handleCall = () => {
      if (!isEmptyObject(selectedFilters)) {
        if (searchInput) {
          resetSearch()
          return
        }
        else if (currPage === 1) getRejectedTasks()
        else setCurrPage(1)
        resetSearch()
      }
    }
    handleCall()
  }, [selectedFilters])

  useEffect(() => {
    if (!isEmptyObject(selectedFilters)) getRejectedTasks()
  }, [currPage])

  useEffect(() => {
    if (searchInput?.length >= SEARCH_DEFAULT_MIN_CHARACTERS || searchClose) {
      resetTableSelection()
      if (currPage === 1) getRejectedTasks()
      else setCurrPage(1)
    }
  }, [searchInput, searchClose])

  const resetTableSelection = () => {
    assetDetailsRef?.current?.resetTablePagination()
    assetDetailsRef?.current?.resetTableSelection()
  }

  const handlePaginationChange = (page) => {
    setCurrPage(page)
  }

  const handleTasksActionSubmit = () => {
    setTasksActionPopUp(initialTasksActionState)
    resetTableSelection()
  }

  const handleSearch = (value, closeAction, hasError) => {
    setSearchClose(closeAction)
    setSearchInput(value)
    if (
      (closeAction && !hasError) ||
          (!closeAction && value.length >= SEARCH_DEFAULT_MIN_CHARACTERS)
    ) {
      setSearchError(false)
    } else {
      setSearchError(true)
    }
  }
  
  const resetSearch = () => {
    setSearchInput(null)
    setSearchError(false)
    setSearchClose(true)
    searchBarRef.current?.reset()
  }

  const searchNoRecordsFoundMsg = (searchInput && rejectedTasks?.length === 0) ?
    t("rejectedTasks.table.noSearchRecord") :
    ""

  return (
    <>
      <HeadSection
        selectedAssets={ selectedAssets }
        setTasksActionPopUp={ setTasksActionPopUp }
        handleSearch={ handleSearch }
        searchBarRef={ searchBarRef }
        searchError={ searchError }
        searchResultCount={ (!isFetching && searchInput) ? paginationDataRef?.current?.totalElements : null }
        isSearchDisabled={ !searchInput && searchInput == null && rejectedTasks?.length === 0 }
      />
      <div className={ styles.assetsTable }>
        { isFetching && currPage === 1 && <Loading /> }
        { isError && !isFetching && (
          <Error error={ { message: t("rejectedTasks.getErrorMessage") } } />
        ) }
        { !isFetching && !isError && searchError && <SearchErrorPage /> }
        { !(isFetching && currPage === 1) && !isError && !searchError && rejectedTasks && (
          <AssetDetails
            tableData={ rejectedTasks }
            setSelectedAssets={ setSelectedAssets }
            currPage={ currPage }
            paginationData={ paginationDataRef?.current }
            handlePaginationChange={ handlePaginationChange }
            queryKey={ queryKey }
            ref={ assetDetailsRef }
            customNoRowsMessage={ searchNoRecordsFoundMsg }
          />
        ) }
      </div>
      <TasksActionPopUp
        handleCancel={ () => setTasksActionPopUp(initialTasksActionState) }
        handleSubmit={ handleTasksActionSubmit }
        queryKey={ queryKey }
        { ...tasksActionPopup }
      />
    </>
  )
}

export default RejectedTasks

/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types"
import React, { createContext, useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { useLocation } from "react-router"
import {  useParams } from "react-router-dom"

import {
  DEFAULT_DATE_RANGE,
  transformStatistics
} from "../../pages/CentralSoftware/CentralSoftwareUpdate/CentralSoftwareUpdatesUtils"
import { softwareSVC, softwareSVCKeys } from "../../services/reactQueries/softwaresvc"
import { useLazyQuery } from "../../utils/CustomHooks/reactQuery"
import { ROUTE_CONFIG } from "../../utils/GlobalConstants"
import { StorageKeys, getSession } from "../../utils/SessionHelper/session"

export const SoftwareUpdateContext = createContext()

export const SoftwareUpdateProvider = ({ children, value = { filter: [] } }) => {
  const queryParams = useParams()
  const { state } = useLocation()

  const { t } = useTranslation(["software"])
  const [filter, setFilter] = useState(value?.filter || [])
  const [dateRange, setDateRange] = useState(state?.dateRange ? state?.dateRange : {
    endDate: DEFAULT_DATE_RANGE.endDate,
    startDate: DEFAULT_DATE_RANGE.startDate
  })

  const [isFilterSet, setIsFilterSet] = useState(false)
  const [isFilterOpen, setIsFilterOpen] = useState(true)
  const [softwareList, setSoftwareList] = useState([])
  const siteUcmId = getSession(StorageKeys.SITE_UCM_ID)
  const refetchQuery = [softwareSVCKeys.GET_ALL_SOFTWARE_LIST_BY_SITE_ID, siteUcmId]

  const { data: softwareStatistics } = useQuery([softwareSVCKeys.GET_CENTRAL_SOFTWARE_STATISTICS, siteUcmId], () =>
    softwareSVC.getSoftwareStatistics(siteUcmId),
  { enabled: !queryParams[ROUTE_CONFIG.SOFTWARE_DEPLOYMENT.param] }
  )
  const softwareStatisticsData = softwareStatistics?.data?.statistics
  const { distributionData, modalityChartData } = transformStatistics(softwareStatisticsData)

  const onGetSoftwarePackagesCompleted = (data) => {
    const packages = data?.data?.packageList
    setSoftwareList(packages)
  }

  const [
    getSoftwarePackages, getQueryResults
  ] = useLazyQuery(refetchQuery, () =>
    softwareSVC.getSoftwarePackagesBySiteUCMId(
      siteUcmId, dateRange, filter
    ),
  { onSuccess: onGetSoftwarePackagesCompleted }
  )

  useEffect(() => {
    return () => getQueryResults.remove()
  }, [])

  useEffect(() => {
    if (filter?.length > 0 && !queryParams[ROUTE_CONFIG.SOFTWARE_DEPLOYMENT.param]) getSoftwarePackages()
  }, [filter, dateRange])

  const setFilters = (payload) => {
    setFilter(payload)
  }

  useEffect(() => {
    if(state?.filter) {
      setFilter(state?.filter || [])
      setDateRange(state?.dateRange)
      setIsFilterOpen(false)
      setIsFilterSet(true)
    }
  }, [])


  const SoftwareUpdateValue = useMemo(() => ({
    dateRange,
    distributionData,
    filter,
    getQueryResults,
    isFilterOpen,
    isFilterSet,
    modalityChartData,
    refetchQuery,
    setDateRange,
    setFilter: setFilters,
    setIsFilterOpen,
    setIsFilterSet,
    setSoftwareList,
    softwareList,
    t
  }))

  return (
    <SoftwareUpdateContext.Provider value={ SoftwareUpdateValue }>
      { children }
    </SoftwareUpdateContext.Provider>
  )
}

SoftwareUpdateProvider.propTypes = {
  children: PropTypes.any,
  value: PropTypes.object
}

export const useSoftwareUpdateContext = () => {
  return useContext(SoftwareUpdateContext)
}
